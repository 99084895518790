import React, { useState, useEffect, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Box, Typography, Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import type { Dossier, Contact, Updater } from 'model'
import { fetchDossier } from 'api/DossierApi'
import { getContactsDossier } from 'api/ContactApi'
import DossierEditView from 'pages/dossier/DossierEditView'
import DossierContactBlock from './DossierContactBlock'
import DossierStatusAdmin from './DossierStatusAdmin'

type TDossiersAdminDetailState = {
  dossier?: Dossier
  contacts?: Contact[]
  dossierLoading: boolean
  contactsLoading: boolean
}

const Loading = () => <Typography variant="h3">...</Typography>

const DossiersAdminDetail = () => {
  const [state, setState] = useState<TDossiersAdminDetailState>({
    dossierLoading: true,
    contactsLoading: true,
  })
  const { dossierId } = useParams()

  const setDossier = useCallback(
    (fn: Updater<Dossier | undefined>) =>
      setState(st => ({ ...st, dossier: fn(st.dossier) })),
    [setState],
  )

  useEffect(() => {
    if (dossierId !== undefined) {
      fetchDossier(dossierId)
        .then(dossier => setState(st => ({ ...st, dossier, dossierLoading: false })))
        .then(() => getContactsDossier(dossierId))
        .then(contacts => setState(st => ({ ...st, contacts, contactsLoading: false })))
    }
  }, [dossierId, setState])

  const { dossier, contacts = [], dossierLoading, contactsLoading } = state
  const contactContent = contacts.map(contact => (
    <DossierContactBlock key={contact.id} contact={contact} />
  ))
  const intl = useIntl()

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: 3 }}
      >
        {[
          <Link key="1" component={RouterLink} to="/re62/admin/dossiers">
            <FormattedMessage
              id={"breadcrumb.dossiers"}
              defaultMessage={"Dossiers"}
            />
          </Link>,
          <Typography key="2" color="text.primary">
            <FormattedMessage
              id={"breadcrumb.detail"}
              defaultMessage={"Détail"}
            />
          </Typography>,
        ]}
      </Breadcrumbs>
      <Box sx={{ mb: 2 }}>
        {dossier !== undefined && (
          <DossierStatusAdmin dossier={dossier} setDossier={setDossier} />
        )}
        {dossierLoading ? (
          <Loading />
        ) : dossier === undefined ? (
          <Typography variant="h3">
            <FormattedMessage
              id={"dossierAdmin.error.invalidId"}
              defaultMessage={"Identifiant de dossier invalide"}
            />
          </Typography>
        ) : (
          <DossierEditView
            dossier={dossier}
            setDossier={setDossier}
            coordsTitle={intl.formatMessage({ id: "dossierAdmin.edit.info", defaultMessage: "Coordonnées du dossier" })}
            dommagesTitle={intl.formatMessage({ id: "dossierAdmin.edit.damage", defaultMessage: "Dommages du dossier" })}
          />
        )}
        <Typography variant="h2">
          <FormattedMessage
            id={"dossierAdmin.detail.contact"}
            defaultMessage={"Contacts du dossier"}
          />
        </Typography>
        {contactsLoading ? (
          <Loading />
        ) : contacts.length === 0 ? (
          <Typography variant="h3">
            <FormattedMessage
              id={"dossierAdmin.detail.noContact"}
              defaultMessage={"Aucun contact sur ce dossier"}
            />
          </Typography>
        ) : (
          contactContent
        )}
      </Box>
    </>
  )
}

export default DossiersAdminDetail
