import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Typography } from '@mui/material'

const APropos = () => (
  <>
    <Typography variant="h4" component="h1" gutterBottom>
      <FormattedMessage
        id={"about.title"}
        defaultMessage={"A props de nous"}
      />
    </Typography>
    TODO
  </>
)
export default APropos
