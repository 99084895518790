import React from 'react'

import { Grid, Button, FormControl, FormLabel, FormGroup } from '@mui/material'

import type { Profession } from '../model'
import { professions, professionLabels } from '../model/Profession'
import { DossierIcon } from 'ui/DossierIcon'
import {FormattedMessage} from 'react-intl'
import {normalizeKey} from '../locales/messages'

type TProfessionInputProps = {
  label?: string
  value: Profession[]
  onChange: (professions: Profession[]) => void
}

const ProfessionInput = (props: TProfessionInputProps) => {
  const { label, value, onChange } = props

  console.warn({ professions })

  const buttons = professions.map(profession => {
    const active = value.indexOf(profession) >= 0
    const label = professionLabels[profession]
    const onClick = () =>
      onChange(active ? value.filter(_ => _ !== profession) : [...value, profession])
    return (
      <Grid item xs={6} sm={4} md={3} key={profession}>
        <Button
          fullWidth
          variant={active ? 'contained' : 'text'}
          onClick={onClick}
          sx={{
            '&:hover': {
              backgroundColor: active ? 'rgb(121, 200, 230)' : '#FFFFFF',
              color: '#102E46',
            },
            display: 'inline-flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: active ? 'rgb(121, 200, 230)' : '#FFFFFF',
            color: '#102E46',
            border: active ? undefined : 1,
          }}
        >
          <DossierIcon type={profession} />
          <FormattedMessage
            id={`professions.${normalizeKey(label)}`}
            defaultMessage={label}
          />
        </Button>
      </Grid>
    )
  })

  return (
    <FormControl>
      {label && <FormLabel sx={{ m: 1 }}>{label}</FormLabel>}
      <FormGroup>
        <Grid container spacing={2}>
          {buttons}
        </Grid>
      </FormGroup>
    </FormControl>
  )
}
export default ProfessionInput
