import React, { useCallback, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

import { Profession } from 'model'
import ProfessionInput from './ProfessionInput'

export type DossierDommages = {
  professions: Profession[]
  description: string
  budget?: number
  logementHabitable: boolean
  nombrePieces: number
  scoreAmplitude: number
}

type TDossierDommagesFormProps = {
  value: DossierDommages
  onChange: (data: Partial<DossierDommages>) => void
}

const identity = (v: string): string => v
const textToNumber = (v: string): number => +v
const textToBoolean = (v: string): boolean => v === 'true'

const DossierDommagesForm = ({ value, onChange }: TDossierDommagesFormProps) => {
  const {
    professions,
    description,
    budget,
    logementHabitable,
    nombrePieces,
    scoreAmplitude,
  } = value
  const [avecExpertise, setAvecExpertise] = useState(budget !== undefined && budget > 0)
  const intl = useIntl()

  const inputChange = useCallback(
    <T extends keyof DossierDommages>(
      field: T,
      toValue: (texteValue: string) => DossierDommages[T],
    ) =>
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        onChange({ [field]: toValue(ev.target.value) }),
    [onChange],
  )
  const expertiseChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setAvecExpertise(textToBoolean(ev.target.value))
      onChange({ budget: 0 })
    },
    [onChange, setAvecExpertise],
  )
  const professionsChange = useCallback(
    (professions: Profession[]) => onChange({ professions }),
    [onChange],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl>
          <FormLabel id="dommages-dossier-logement-habitable">
            <FormattedMessage
              id={'dossierDamage.fields.logement'}
              defaultMessage={'Logement habitable'}
            />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="dommages-dossier-logement-habitable"
            name="logement-habitable"
            value={logementHabitable ? 'true' : 'false'}
            onChange={inputChange('logementHabitable', textToBoolean)}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={intl.formatMessage({ id: 'dossierDamage.fields.logement.oui', defaultMessage: 'Oui'})}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={intl.formatMessage({ id: 'dossierDamage.fields.logement.non', defaultMessage: 'Non'})}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="nombrePieces"
          fullWidth
          id="nombrePieces"
          label={intl.formatMessage({ id: 'dossierDamage.fields.nombrePieces', defaultMessage: 'Nombre de pièces endommagées' })}
          value={nombrePieces === 0 ? '' : '' + nombrePieces}
          onChange={inputChange('nombrePieces', textToNumber)}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl>
          <FormLabel id="dommages-dossier-expertise">
            <FormattedMessage
              id={'dossierDamage.fields.expertise'}
              defaultMessage={'Une expertise a été réalisée'}
            />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="dommages-dossier-expertise"
            name="expertise"
            value={avecExpertise ? 'true' : 'false'}
            onChange={expertiseChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={intl.formatMessage({ id: 'dossierDamage.fields.expertise.oui', defaultMessage: 'Oui' })}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={intl.formatMessage({ id: 'dossierDamage.fields.expertise.non', defaultMessage: 'Non' })}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="budget"
          fullWidth
          id="budget"
          label={intl.formatMessage({ id: 'dossierDamage.fields.montantEstime', defaultMessage: 'Montant estimé des travaux (devis ou avis d\'expert)' })}
          value={budget === undefined || budget === 0 ? '' : '' + budget}
          onChange={inputChange('budget', textToNumber)}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          disabled={!avecExpertise}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ProfessionInput
          label={intl.formatMessage({ id: 'dossierDamage.fields.travaux', defaultMessage: 'Les travaux concernent' })}
          value={professions}
          onChange={professionsChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="description"
          fullWidth
          multiline
          id="description"
          label={intl.formatMessage({ id: 'dossierDamage.fields.description', defaultMessage: 'Descripton des dommages' })}
          value={description}
          onChange={inputChange('description', identity)}
          inputProps={{ minRows: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl>
          <FormLabel id="dommages-dossier-ampleur">
            <FormattedMessage
              id={'dossierDamage.fields.scoreAmplitude'}
              defaultMessage={'L\'ampleur des dommages est'}
            />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="dommages-dossier-ampleur"
            name="scoreAmplitude"
            value={scoreAmplitude}
            onChange={inputChange('scoreAmplitude', textToNumber)}
          >
            <FormControlLabel value="1" control={<Radio />} label={intl.formatMessage({ id: 'dossierDamage.fields.scoreAmplitude.1', defaultMessage: 'Faible' })} />
            <FormControlLabel value="5" control={<Radio />} label={intl.formatMessage({ id: 'dossierDamage.fields.scoreAmplitude.2', defaultMessage: 'Moyenne' })} />
            <FormControlLabel value="9" control={<Radio />} label={intl.formatMessage({ id: 'dossierDamage.fields.scoreAmplitude.3', defaultMessage: 'Elevée' })} />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}
export default DossierDommagesForm
