import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Link as RouterLink } from 'react-router-dom'

import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const Confidentialite = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: 6 }}
      >
        {[
          <Link key="1" component={RouterLink} to="/re62">
            <FormattedMessage
              id={"breadcrumb.home"}
              defaultMessage={"Accueil"}
            />
          </Link>,
          <Typography key="2" color="text.primary">
            <FormattedMessage
              id={"breadcrumb.confidentiality"}
              defaultMessage={"Politique de confidentialité"}
            />
          </Typography>,
        ]}
      </Breadcrumbs>

      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Protection des données personnelles"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Nous recueillons vos données personnelles et les utilisons pour la gestion de cette demande et pour notre relation commerciale. Elles sont destinées prioritairement aux entreprises du Groupe SARETEC et à votre intermédiaire en assurance ; mais également aux différents organismes et partenaires directement impliqués dans votre contrat."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Ces destinataires se situent parfois en dehors de l’Union Européenne. Dans ce cas, nous concevons des dispositions spécifiques pour assurer la protection complète de vos données."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Vos informations personnelles nous aident à mieux vous connaître, et ainsi à vous proposer des solutions et services qui vous correspondent. Nous les conservons tout au long de la vie de votre contrat. Une fois ce dernier fermé, elles sont conservées pendant le délai de prescription."}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Vous gardez bien sûr tout loisir d’y accéder, de demander leur rectification, portabilité ou effacement et de vous opposer à leur utilisation. Vous pouvez également prendre contact avec le Délégué à la Protection des Données Personnelles (DPO) pour toute information ou contestation (loi 'Informatique et Libertés' du 6 janvier 1978). Pour cela, il vous suffit de nous écrire :"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"- par mail à serviceclients@saretec.fr,"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"- par courrier à l’adresse 9 Rue Georges Enesco, 94000 Créteil."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Vous pouvez également vous adresser à la CNIL."}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.data.title"}
          defaultMessage={"Pour plus de détail, reportez-vous aux documents contractuels, notamment les dispositions générales ou notices d’information et les sites internet de Saretec. Protéger les victimes et nous protéger nous-mêmes est au cœur de la politique de maîtrise des risques de Saretec et de la lutte anti-fraude. Aussi, nous gardons la possibilité de vérifier ces informations et de saisir, si nécessaire, les autorités compétentes."}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.title"}
          defaultMessage={"Utilisation des cookies"}
        />
      </Typography>
      <Typography variant="h3" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.1"}
          defaultMessage={"En quoi consistent les cookies ? Les cookies, un outil indispensable"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.2"}
          defaultMessage={"Il s’agit d’un simple fichier texte stocké temporairement ou définitivement sur votre périphérique fixe ou mobile (mais aussi dans certains cas, dans votre navigateur web) à la demande du serveur du site sur lequel vous naviguez. Il est renvoyé au site web concerné pour lui permettre de vous reconnaître lors de vos visites ultérieures."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.3"}
          defaultMessage={"Mal connu des internautes, le cookie est utile, voire indispensable pour la navigation sur internet car il permet aux éditeurs de sites internet :"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.4"}
          defaultMessage={"✓ d’améliorer la performance de leur site"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.5"}
          defaultMessage={"✓ de proposer une meilleure expérience de navigation aux internautes."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.6"}
          defaultMessage={"Il permet en effet de retracer l’historique de consultation des pages du site visité, et cela, sur tous les supports existants : mobiles, tablettes ou ordinateur. En apprenant à connaitre le comportement des internautes sur ses sites, le groupe Saretec pourra :"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.7"}
          defaultMessage={"✓ établir des statistiques fiables de fréquentation"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.8"}
          defaultMessage={"✓ mieux comprendre leurs besoins"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.9"}
          defaultMessage={"✓ sécuriser ses procédures"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.10"}
          defaultMessage={"✓ mémoriser les informations remplies sur les parcours de souscription"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.firstSection.11"}
          defaultMessage={"✓ vous permettre de vous rendre dans votre espace personnel."}
        />
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.secondSection.1"}
          defaultMessage={"Quels types de cookies utilisons-nous ?"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.secondSection.2"}
          defaultMessage={"Comme la plupart des sites internet, nous utilisons des cookies. En poursuivant la navigation sur notre site vous avez consenti à l’usage de l’ensemble des 4 types de cookies suivants :"}
        />
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.thirdSection.1"}
          defaultMessage={"✓ Les cookies fonctionnels"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.thirdSection.2"}
          defaultMessage={"Sans eux, vous ne pourriez tout simplement pas naviguer sur Saretec.fr et en utiliser les différentes fonctionnalités. Ils permettent notamment d’accéder à son espace personnel et de consulter l’ensemble des données qui y sont stockées : devis sauvegardés, contrat(s) en cours et documentation. Ils nous permettent en outre de garder en mémoire certaines des informations que vous nous communiquez, par exemple lors d’une demande de devis. Ils permettent d’archiver vos préférences de navigation sur Saretec.fr et de garder ainsi en mémoire les différents paramètres que vous avez choisis lors de votre dernière visite comme la version du site consultée (mobile, tablette ou ordinateur). ✓ Les cookies de performance et de mesure d'audience Ces cookies recueillent anonymement un certain nombre d’informations lors de vos visites sur Saretec.fr, comme la manière dont vous êtes arrivé sur le site ou encore les pages que vous fréquentez le plus souvent. L'objectif de ces cookies est de mesurer la fréquentation de nos pages et de nous permettre d’analyser et de mieux comprendre les comportements des internautes sur le site en vue d’en améliorer les services, le contenu et le fonctionnement."}
        />
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.fourthSection.1"}
          defaultMessage={"✓ Les cookies de publicité"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.fourthSection.2"}
          defaultMessage={"Ces cookies ont pour objectif de cibler au mieux nos campagnes publicitaires sur internet. Ils nous permettent, directement ou via nos partenaires (Google, Facebook ...), de vous proposer des bannières publicitaires personnalisées en fonction de votre expérience de navigation sur notre site. Exemple : imaginons que vous avez entré une requête concernant l’assurance auto dans un moteur de recherche et que vous avez ainsi navigué dans la fiche produit correspondante sur notre site. Dans ce cas, lors de vos visites ultérieures sur les sites de nos partenaires, il est possible qu’une bannière présentant notre assurance auto vous soit proposée. Ainsi, en fonction des pages fréquentées par nos internautes, nous personnalisons nos bannières afin de leur proposer les contenus et les offres les plus adaptés et les plus pertinents. Les cookies ne tracent pas les adresses IP des utilisateurs."}
        />
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.fifthSection.1"}
          defaultMessage={"✓ Les cookies des réseaux sociaux"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.fifthSection.2"}
          defaultMessage={"Saretec.fr propose à l’internaute des boutons de partage de contenu via les réseaux sociaux. Ces boutons sont mis à disposition par les réseaux sociaux et font appel aux cookies afin de suivre la navigation des internautes. Même si l’internaute ne clique pas sur les boutons 'réseaux sociaux' disponibles sur Saretec.fr, les réseaux sociaux concernés sont susceptibles d’identifier sa navigation sur le site. Les cookies sont déposés directement par les réseaux sociaux. Par suite, les choix de l’internaute concernant le dépôt de ces cookies doivent également s’effectuer auprès des réseaux sociaux. A cette fin, vous êtes invité à consulter directement ces sites et leur politique de protection des données personnelles. Si vous souhaitez en savoir plus sur les cookies, leur fonctionnement et leur utilisation, vous pouvez vous rendre sur le site de la CNIL : CNIL, les outils pour maîtriser les cookies"}
        />
      </Typography>

      <Typography variant="h3" component="h3" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.1"}
          defaultMessage={"Comment gérer les cookies ?"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.2"}
          defaultMessage={"La gestion des cookies se paramètre dans son navigateur web"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.3"}
          defaultMessage={"Vous pouvez ainsi paramétrer votre navigateur de façon qu’il :"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.4"}
          defaultMessage={"- refuse systématiquement l’envoi de cookies"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.5"}
          defaultMessage={"- n’accepte l’envoi de cookies que pour certains sites considérés comme étant 'de confiance'"}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.6"}
          defaultMessage={"- n’accepte l’envoi de cookies que pour le site sur lequel vous êtes en train de naviguer."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.cookie.sixthSection.7"}
          defaultMessage={"Notre conseil : si vous souhaitez bénéficier de l’ensemble des fonctionnalités existantes sur notre site, veuillez ne pas désactiver les cookies, votre expérience de navigation en deviendrait alors moins fluide."}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.info.title"}
          defaultMessage={"Informations sur les produits et services"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.info.1"}
          defaultMessage={"Ces informations sont susceptibles d’évoluer à tout moment sans préavis."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.2"}
          defaultMessage={"Saretec s’efforcera d'assurer leur mise à jour au fur et à mesure des évolutions apportées à ses produits et services, ainsi que des évolutions législatives ou réglementaires éventuelles. Saretec s’engage à vous remettre sur simple demande, les dispositions contractuelles en vigueur afférentes à ces produits et services."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.3"}
          defaultMessage={"Saretec ne pourra en aucun cas être tenu pour responsable en cas de retard de mise à jour, d'erreur ou d'omission quant au contenu des pages du site. Saretec ne saurait être tenu pour responsable des dommages directs et indirects résultant de l'accès ou de l'usage du site."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.4"}
          defaultMessage={"Le site Saretec contient par ailleurs des liens hypertextes orientant l’internaute vers d’autres sites internet."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.5"}
          defaultMessage={"Saretec n’est pas responsable du contenu des informations accessibles via son site au moyen de ces liens hypertextes. Ainsi Saretec ne peut garantir leur exactitude, mise à jour, caractère actuel et loyal, qui relèvent de la seule et entière responsabilité des personnes auteurs ou propriétaires de ces sites."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.6"}
          defaultMessage={"Les simulateurs mis en ligne sur le site Saretec ont été conçus pour vous fournir des informations ou des valeurs prévisionnelles, à titre indicatif. Les estimations sont calculées sur la base des données de simulation communiquées par l’internaute au jour de leur saisie et de la réglementation en vigueur à cette date, mais ne sauraient constituer un indicateur fiable, ni servir de justificatif vis-à-vis de toute Administration."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.info.7"}
          defaultMessage={"Vous êtes invité à vous rapprocher d’un Conseiller Saretec pour toute demande de précision, communication d’informations complémentaires ou conseils sur les produits et services d’Saretec présentés sur le site."}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.warning.title"}
          defaultMessage={"Avertissement"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.warning.1"}
          defaultMessage={"Les produits et services présentés sur le site sont destinés aux résidents français."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.warning.2"}
          defaultMessage={"Le site et son contenu font l'objet d'une protection légale au titre de la propriété littéraire et artistique (droits d'auteurs, des interprètes...) et de la propriété industrielle (marques, dessins...)."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.warning.3"}
          defaultMessage={"Il est interdit à tout internaute de copier ou reproduire, par numérisation et tout autre moyen, sur quelque support que ce soit, tout ou partie du contenu du site, qu'il soit textuel, graphique ou sonore, sauf pour son usage propre. Le site indique en particulier à l'internaute les parties du contenu du site qu'il est autorisé à télécharger pour les besoins de sa relation avec Saretec."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.warning.4"}
          defaultMessage={"Le non-respect de ces règles peut engager la responsabilité civile et pénale du contrevenant."}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.contact.title"}
          defaultMessage={"Coordonnées des organismes pour l'affiliation des agents"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.contact.1"}
          defaultMessage={"Organisme d'immatriculation : ORIAS, site internet www.orias.fr."}
        />
        <br />
        <FormattedMessage
          id={"confidentiality.contact.2"}
          defaultMessage={"Autorité de contrôle prudentiel et de résolution : ACPR - 4 place de Budapest - CS 92459 - 75436 Paris Cedex 09."}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"confidentiality.demarchage.title"}
          defaultMessage={"Démarchage téléphonique"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"confidentiality.demarchage.1"}
          defaultMessage={"Si vous ne souhaitez pas faire l’objet de prospection commerciale par téléphone, vous pouvez gratuitement vous inscrire sur une liste d’opposition au démarchage téléphonique."}
        />
      </Typography>
    </Grid>
  </Grid>
)
export default Confidentialite
