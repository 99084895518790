import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Chip } from '@mui/material'

import { Profession } from 'model'
import { DossierIcon } from './DossierIcon'
import { RecapItem } from './RecapItem'
import {normalizeKey} from '../locales/messages'

type TProfessionChipsProps = {
  title: string
  professions: Profession[]
  reference?: Profession[]
}

export const ProfessionChips = ({
  title,
  professions,
  reference = [],
}: TProfessionChipsProps) => {
  const intl = useIntl()
  const sortedProfessions = [...professions].sort((a, b) => {
    const aIsActive = +reference.includes(a)
    const bIsActive = +reference.includes(b)
    return bIsActive - aIsActive
  })

  const chips = sortedProfessions.map(p => {
    const matches = reference.indexOf(p) >= 0
    const otherStyles = matches
      ? { backgroundColor: '#79C8E6', color: '#102E46' }
      : {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          color: '#292929',
          fontStyle: 'italic',
        }
    return (
      <Chip
        sx={{ mr: 1, mb: 1, pl: 1.5, py: 1, height: '100%', ...otherStyles }}
        key={p}
        icon={<DossierIcon type={p} />}
        label={intl.formatMessage({ id: `professions.${normalizeKey(p)}`, defaultMessage: p })}
      />
    )
  })
  const wrapped = (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, mb: -1 }}>
      {chips}
    </Box>
  )

  return <RecapItem title={title} value={wrapped} />
}
