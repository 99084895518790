import React from 'react'
import { IntlProvider } from 'react-intl'
import { getBrowserLanguage, messages } from './locales/messages'

import SessionProvider from 'stores/SessionProvider'
import Router from 'Router'

const App: React.FC = () => {
  const defaultLocale = 'fr'
  const locale = getBrowserLanguage()
  const currentMessages = messages[locale]

  return (
    <SessionProvider>
      <IntlProvider defaultLocale={defaultLocale} locale={locale} messages={currentMessages}>
        <Router />
      </IntlProvider>
    </SessionProvider>
  )
}

export default App
