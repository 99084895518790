import React, { useState, useCallback, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Grid, Button, Alert, TextField } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'

import type { ApiError, FormState } from 'model'
import { isError } from 'model'
import { artisanPasswordLost } from 'api/ArtisanApi'

import firstBanner from 'assets/HP1.png'

type ArtisanPasswordLostData = {
  email: string
}

const emptyArtisanLoginData: ArtisanPasswordLostData = { email: '' }

const loginSx = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
  textAlign: 'center',
}

type TArtisanPasswordLostPageState = {
  formState: FormState
  errorMessage?: string
}

const LoginArtisanPage = () => {
  const [artisanPasswordLostData, setArtisanPasswordLostData] =
    useState<ArtisanPasswordLostData>(emptyArtisanLoginData)
  const [state, setState] = useState<TArtisanPasswordLostPageState>({
    formState: 'ready',
  })
  const navigate = useNavigate()
  const intl = useIntl()

  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    ev => {
      ev.preventDefault()
      const { email } = artisanPasswordLostData
      const recaptcha = captchaRef?.current?.getValue() || ''
      if (email === '') {
        setState(prev => ({
          ...prev,
          formState: 'error',
          errorMessage: intl.formatMessage({ id: 'passwordLost.error.fields', defaultMessage: 'Veuillez remplir tous les champs' }),
        }))
      } else if (recaptcha === '' && recaptchaEnabled) {
        setState(prev => ({
          ...prev,
          formState: 'error',
          errorMessage: intl.formatMessage({ id: 'passwordLost.error.captcha', defaultMessage: 'Veuillez valider le Captcha' }),
        }))
      } else {
        setState(prev => ({ ...prev, formState: 'loading', errorMessage: undefined }))
        artisanPasswordLost({ email, recaptcha }).then((res: string | ApiError) => {
          if (!isError(res)) {
            setState(prev => ({ ...prev, formState: 'success' }))
            navigate('/re62/')
          } else {
            setState(prev => ({
              ...prev,
              formState: 'error',
              errorMessage: intl.formatMessage({ id: 'passwordLost.error.default', defaultMessage: 'Une erreur est survenue' }),
            }))
          }
        })
      }
    },
    [artisanPasswordLostData, navigate, setState, captchaRef, recaptchaEnabled],
  )

  const setField = useCallback(
    (field: keyof ArtisanPasswordLostData, fieldValue: string) =>
      setArtisanPasswordLostData({ ...artisanPasswordLostData, [field]: fieldValue }),
    [artisanPasswordLostData, setArtisanPasswordLostData],
  )
  const inputChange = useCallback(
    (field: keyof ArtisanPasswordLostData): React.ChangeEventHandler<HTMLInputElement> =>
      ev =>
        setField(field, ev.target.value),
    [setField],
  )

  const { formState, errorMessage } = state
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box sx={loginSx}>
          <Typography variant="h1" component="h1" gutterBottom>
            <FormattedMessage
              id={'passwordLost.title'}
              defaultMessage={'Reconstruire Ensemble 62'}
            />
          </Typography>
          <Typography variant="subtitle1" component="span" sx={{ mb: 6 }}>
            <FormattedMessage
              id={'passwordLost.subtitle'}
              defaultMessage={'Saisissez votre email pour recevoir un lien de réinitialisation de votre mot de passe.'}
            />
          </Typography>
          <form onSubmit={onSubmit}>
            <TextField
              autoComplete="email"
              name="email"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: 'passwordLost.mail', defaultMessage: 'Adresse e-mail' })}
              value={artisanPasswordLostData.email}
              onChange={inputChange('email')}
            />
            {recaptchaEnabled && (
              <Box
                sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center' }}
              >
                <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
              </Box>
            )}
            <Button
              fullWidth
              sx={{ my: 2 }}
              variant="contained"
              type="submit"
              color={formState === 'error' ? 'error' : 'primary'}
              disabled={formState === 'loading'}
            >
              <FormattedMessage
                id={'passwordLost.btn'}
                defaultMessage={'Valider'}
              />
            </Button>
          </form>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <img
          src={firstBanner}
          style={{ width: '100%', borderRadius: 24 }}
          alt="First Banner"
        />
      </Grid>
    </Grid>
  )
}
export default LoginArtisanPage
