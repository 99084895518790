
export const getBrowserLanguage = () => {
  const pathname = window.location.pathname
  return pathname.includes('/rj46') ? 'es' : 'fr'
}

export function normalizeKey(key: string) {
  return key
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "")
}

export type Messages = {
  readonly [locale: string]: {
    readonly [key: string]: string
  }
}

export const messages: Messages = {
  fr: {
    "home.layout.contact": "Nous contacter",
    "home.layout.signIn": "Accès réparateur",
    "home.layout.account": "Mon compte",
    "home.hero.title": "Reconstruire Ensemble 62",
    "home.hero.description.1": "Bienvenue sur votre solution de mise en contact entre sinistrés et artisans",
    "home.hero.description.2": "Ce service est disponible jusqu'au 30 juin 2024.",
    "home.hero.btns.1": "Je suis sinistré",
    "home.hero.btns.2": "Je suis une entreprise de réparation",
    "home.section1.title": "Je suis sinistré, comment ça marche ?",
    "home.section1.description.1": "C'est très simple, vous déposez une demande de travaux, un artisan se propose pour les réaliser.",
    "home.section1.description.2": "Nous vous transmettons ses coordonnées pour que vous puissiez échanger. A vous de jouer !",
    "home.section1.description.3": "Le service sera disponible jusqu'au 30 juin 2024.",
    "home.section2.title": "Je suis une entreprise de réparation, comment ça marche ?",
    "home.section2.description.1": "Découvrez les besoins en travaux des habitants du Pas-de-Calais. Inscrivez-vous simplement et positionnez-vous sur les demandes de travaux transmises par les sinistrés !",
    "home.section2.description.2": "Le service sera disponible jusqu'au 30 juin 2024.",
    "home.section3.title": "Le service Reconstruire Ensemble 62 est 100% gratuit",
    "home.section3.description.1": "Gratuit pour les sinistrés par la tempête Ciaran, qui cherchent des artisans pour réaliser leurs réparations.",
    "home.section3.description.2": "Gratuit pour les artisans souhaitant connaitre les besoins en réparation dans un secteur donné.",
    "home.section3.description.3": "Le service sera disponible jusqu'au 30 juin 2024.",
    "home.footer.links.1": "Mentions légales",
    "home.footer.links.2": "Politique de confidentialité",
    "home.footer.links.3": "Nous contacter",
    "home.footer.copyright": "Copyright © Keywiiz by ",

    "breadcrumb.home": "Accueil",
    "breadcrumb.createDossier": "Création demande",
    "breadcrumb.passwordLost": "Mot de passe oublié",
    "breadcrumb.reparateur": "Réparateur",
    "breadcrumb.detail": "Détail",
    "breadcrumb.dossiers": "Dossiers",
    "breadcrumb.dashboard": "Dashboard",
    "breadcrumb.artisan": "Profil réparateur",
    "breadcrumb.confidentiality": "Profil réparateur",
    "breadcrumb.legal": "Mentions légales",

    "createDossier.title": "Je renseigne ma demande de travaux",
    "createDossier.section1.title": "Mes coordonnées",
    "createDossier.section1.info": "Cette solution est actuellement destinée aux personnes sinistrées du département Pas-de-Calais",
    "createDossier.section2.title": "Mes dommages",
    "createDossier.section2.description": "Veuillez renseigner ci-dessous les informations concernant votre demande de travaux",
    "createDossier.validateBtn": "Valider ma demande de travaux",
    "createDossier.error.data": "Je renseigne ma demande de travaux",
    "createDossier.error.captcha": "Je renseigne ma demande de travaux",
    "createDossier.alert.success": "Votre dossier a été créé mais n'est pas encore actif. Veuillez consulter vos emails et cliquer sur le lien pour l'activer",

    "dossierDamage.fields.logement": "Logement habitable",
    "dossierDamage.fields.logement.oui": "Oui",
    "dossierDamage.fields.logement.non": "Non",
    "dossierDamage.fields.nombrePieces": "Nombre de pièces endommagées",
    "dossierDamage.fields.expertise": "Une expertise a été réalisée",
    "dossierDamage.fields.expertise.oui": "Oui",
    "dossierDamage.fields.expertise.non": "Non",
    "dossierDamage.fields.montantEstime": "Montant estimé des travaux (devis ou avis d'expert)",
    "dossierDamage.fields.travaux": "Les travaux concernent",
    "dossierDamage.fields.description": "Descripton des dommages",
    "dossierDamage.fields.scoreAmplitude": "L'ampleur des dommages est",
    "dossierDamage.fields.scoreAmplitude.1": "Faible",
    "dossierDamage.fields.scoreAmplitude.2": "Moyenne",
    "dossierDamage.fields.scoreAmplitude.3": "Élevée",

    "dossierInfo.fields.name": "Nom ou raison sociale",
    "dossierInfo.fields.postalCode": "Code postal",
    "dossierInfo.fields.email": "Adresse e-mail",
    "dossierInfo.fields.phone": "Numéro de téléphone",

    "dossierArtisan.noDossier": "Aucun dossier disponible",
    "dossierArtisan.dossier": "Dossiers disponibles",
    "dossierArtisan.candidature": "Mes candidature",
    "dossierArtisan.error.noCertificat.1": "Veuillez importer votre certificat d’assurance de l’année en cours afin de compléter votre profil.",
    "dossierArtisan.error.noCertificat.2": "Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail.",
    "dossierArtisan.error.certificatWait": "Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail.",
    "dossierArtisan.info.1": "Les dossiers listés ici nécessitent <b>au moins</b> l’une des compétences que vous avez renseignées.",
    "dossierArtisan.info.2": "Une compétence est <b>grisée</b> si elle ne fait pas partie de votre liste.",
    "dossierArtisan.contact.1": "Aucun artisan positionné",
    "dossierArtisan.contact.2": "1 artisan positionné",
    "dossierArtisan.contact.3": "artisans positionnés",
    "dossierArtisan.chip.sent": "Candidature envoyée",
    "dossierArtisan.chip.closed": "Dossier clos",
    "dossierArtisan.tooltip": "Candidature bloquée, certificat(s) en attente(s) de validation",
    "dossierArtisan.professionsChip.title": "Compétences nécessaires",
    "dossierArtisan.createdAt": "Créé le",
    "dossierArtisan.updatedAt": "Mis à jour le",
    "dossierArtisan.btn.candidate": "Candidater",

    "dossierAdmin.noArtisan": "Aucun artisan positionné",
    "dossierAdmin.oneArtisan": "1 artisan positionné",
    "dossierAdmin.artisans": "artisans positionnés",
    "dossierAdmin.detail": "Détails dossier",
    "dossierAdmin.skills": "Compétences nécessaires",
    "dossierAdmin.createdAt": "Créé le",
    "dossierAdmin.updatedAt": "Mis à jour le",
    "dossierAdmin.contact.name": "Nom",
    "dossierAdmin.contact.phone": "Téléphone",
    "dossierAdmin.contact.address": "Adresse",
    "dossierAdmin.contact.detail": "Détails réparateur",
    "dossierAdmin.contact.skills": "Compétences",
    "dossierAdmin.status.title": "Statut du dossier",
    "dossierAdmin.status.initiated": "Initié par l'entreprise",
    "dossierAdmin.status.contacted": "Entreprise contactée",
    "dossierAdmin.status.devisValidated": "Devis validé",
    "dossierAdmin.status.aborted": "Contact annulé",
    "dossierAdmin.error.invalidId": "Identifiant de dossier invalide",
    "dossierAdmin.edit.info": "Coordonnées du dossier",
    "dossierAdmin.edit.damage": "Dommages du dossier",
    "dossierAdmin.detail.contact": "Contacts du dossier",
    "dossierAdmin.detail.noContact": "Aucun contact sur ce dossier",
    "dossierAdmin.filter.title": "Status",
    "dossierAdmin.filters.all": "Tous",
    "dossierAdmin.filters.waiting": "En attente",
    "dossierAdmin.filters.opened": "Ouverts",
    "dossierAdmin.filters.solved": "Résolus",

    "dossierRecap.title": "Ma demande de travaux",
    "dossierRecap.subtitle": "Récapitulatif de ma demande",
    "dossierRecap.contact.title.1": "Aucun réparateur ne vous a contacté, un peu de patience",
    "dossierRecap.contact.title.2": "Un réparateur disponible",
    "dossierRecap.contact.title.3": "réparateurs disponibles",
    "dossierRecap.content.dossier": "Dossier n°",
    "dossierRecap.content.habitation.title": "Relogement",
    "dossierRecap.content.habitation.oui": "Oui",
    "dossierRecap.content.habitation.non": "Non",
    "dossierRecap.content.damagedRooms": "Pièces endommagées",
    "dossierRecap.content.amplitude": "Ampleur",
    "dossierRecap.content.amount.title": "Montant de l'expertise",
    "dossierRecap.content.amount.suffix": "€ HT",
    "dossierRecap.content.description": "Description des dommages",
    "dossierRecap.content.professions": "Les travaux concernent",
    "dossierRecap.content.btn.edit": "Modifier ma demande",
    "dossierRecap.content.btn.close": "Clôturer ma demande",

    "contact.btn.initiated": "J'ai contacté l'entreprise",
    "contact.btn.called": "J'ai contacté l'entreprise",
    "contact.btn.accepted": "J'ai accepté le devis",
    "contact.btn.canceled": "Contact annulé",
    "contact.name": "Nom",
    "contact.phone": "Téléphone",
    "contact.address": "Adresse",
    "contact.skills": "Compétences",

    "artisanActivation.loading": "Activation de votre compte ...",
    "artisanActivation.error.title": "Ce lien d'activation est déjà utilisé ou incorrect",
    "artisanActivation.error.btn": "Connexion",
    "artisanActivation.default.title": "Votre Compte a bien été activé",
    "artisanActivation.default.btn": "Connexion",

    "artisanAdmin.skills": "Compétences",
    "artisanAdmin.error": "Une erreur est survenue",
    "artisanAdmin.contact": "Une erreur est survenue",
    "artisanAdmin.updateProfile": "Mettre à jour le profil",
    "artisanAdmin.certificates": "Certificats",
    "artisanAdmin.alert.success": "Profil mis à jour",
    "artisanAdmin.filters.status": "Status",
    "artisanAdmin.filters.all": "Tous",
    "artisanAdmin.filters.sent": "Certificats en attentes de validation",
    "artisanAdmin.filters.validated": "Certificats validés",
    "artisanAdmin.certificat.validate": "Valider",
    "artisanAdmin.certificat.deny": "Refuser",
    "artisanAdmin.certificat.download": "Télécharger",
    "artisanAdmin.certificat.status.denied": "Refusé",
    "artisanAdmin.certificat.status.validated": "Validé",
    "artisanAdmin.certificat.status.waiting": "En attende de validation",

    "passwordLost.title": "Reconstruire Ensemble 62",
    "passwordLost.subtitle": "Saisissez votre email pour recevoir un lien de réinitialisation de votre mot de passe.",
    "passwordLost.mail": "Adresse e-mail",
    "passwordLost.btn": "Valider",
    "passwordLost.error.fields": "Veuillez remplir tous les champs",
    "passwordLost.error.captcha": "Veuillez valider le Captcha",
    "passwordLost.error.default": "Une erreur est survenue",

    "login.email": "Adresse e-mail",
    "login.password": "Mot de passe",
    "login.admin.identifiant": "Identifiant",
    "login.admin.otp": "OTP",

    "resetPassword.title": "Réinitialisation du mot de passe",
    "resetPassword.form.password": "Mot de passe",
    "resetPassword.form.password.error.1": "Les mots de passe ne correspondent pas",
    "resetPassword.form.password.error.2": "Champ requis",
    "resetPassword.form.confirmation": "Confirmation de mot de passe",
    "resetPassword.btn.update": "Mettre à jour",
    "resetPassword.success.alert.1": "Votre mot de passe a été mis à jour.",
    "resetPassword.success.alert.2": "Vous pouvez maintenant",
    "resetPassword.success.alert.3": "vous connecter",
    "resetPassword.success.alert.4": "avec votre nouveau mot de passe.",
    "resetPassword.error.fields": "Veuillez remplir les champs requis.",
    "resetPassword.error.captcha": "Veuillez valider le Captcha",

    "inscription.form.field.error": "Champ requis",
    "inscription.form.name": "Votre compte a été créé. Veuillez consulter vos emails pour finaliser votre inscription",
    "inscription.form.name.error": "Nom invalide",
    "inscription.form.siret": "VNuméro de SIRET",
    "inscription.form.siret.error": "Siret invalide",
    "inscription.form.email": "Adresse e-mail",
    "inscription.form.email.error": "Email invalide",
    "inscription.form.phone": "Numéro de téléphone",
    "inscription.form.phone.error": "Téléphone invalide",
    "inscription.success.alert": "Votre compte a été créé. Veuillez consulter vos emails pour finaliser votre inscription",
    "inscription.error.fields": "Veuillez remplir les champs requis.",
    "inscription.error.captcha": "Veuillez valider le Captcha",

    "administration.title": "Votre compte ne permet pas l'administration RE62",
    "administration.description": "Veuillez utiliser un compte SUPER_ADMIN",
    "administration.dossiers": "Dossiers",
    "administration.reparateurs": "Réparateurs",
    "administration.logout": "Déconnexion",

    "loginAdmin.title": "RE62 Admin",
    "loginAdmin.login": "Connexion",

    "loginArtisan.title": "Reconstruire Ensemble 62",
    "loginArtisan.noAccount": "Pas encore de compte ?",
    "loginArtisan.signup": "Inscription",
    "loginArtisan.login": "Connexion",
    "loginArtisan.lostPassword": "Mot de passe oublié ?",
    "loginArtisan.error.fields": "Veuillez remplir tous les champs",
    "loginArtisan.error.captcha": "Veuillez valider le Captcha",

    "artisan.infos": "Mes coordonnées",
    "artisan.skills": "Mes compétences",
    "artisan.certificates": "Mes certificats",
    "artisan.alert.error.first": "Veuillez mettre à jour les compétences de votre entreprise pour accéder à la liste des dossiers",
    "artisan.alert.error.second.1": "Veuillez importer votre certificat d’assurance de l’année en cours afin de compléter votre profil.",
    "artisan.alert.error.second.2": "Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail.",
    "artisan.alert.error.third": "Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail.",
    "artisan.alert.profilUpdated": "Profil mis à jour",
    "artisan.updateProfile": "Mettre à jour mon profil",
    "artisan.recap.number": "Artisan n°",
    "artisan.recap.name": "Nom",
    "artisan.recap.siret": "SIRET",
    "artisan.recap.email": "Email",
    "artisan.recap.phone": "Téléphone",
    "artisan.recap.address": "Adresse",

    "certificat.title.1": "Certificat d'assurance",
    "certificat.title.2": "Autres certificats",
    "certificat.item.status.denied": "Refusé",
    "certificat.item.status.validated": "Validé",
    "certificat.item.status.default": "En attente de validation",
    "certificat.item.download": "Télécharger",
    "certificat.item.validate": "Valider",
    "certificat.item.deny": "Refuser",
    "certificat.item.delete": "Supprimer",
    "certificat.upload": "Télécharger un certificat",

    "professions.peinture": "Peinture",
    "professions.carrelage": "Carrelage",
    "professions.parquet": "Parquet",
    "professions.menuiserie": "Menuiserie",
    "professions.platrerie": "Plâtrerie",
    "professions.plomberie": "Plomberie",
    "professions.electricite": "Électricité",
    "professions.miroiterie": "Miroiterie",
    "professions.charpente": "Charpente",
    "professions.maconnerie": "Maçonnerie",
    "professions.couverture": "Couverture",
    "professions.serrurerie": "Serrurerie",
    "professions.assechement": "Assèchement",
    "professions.travauxpublics": "Travaux Publics",

    "about.title": "A props de nous",
    "confidentiality.data.title": "Protection des données personnelles",
    "confidentiality.data.firstSection.1": "Nous recueillons vos données personnelles et les utilisons pour la gestion de cette demande et pour notre relation commerciale. Elles sont destinées prioritairement aux entreprises du Groupe SARETEC et à votre intermédiaire en assurance ; mais également aux différents organismes et partenaires directement impliqués dans votre contrat.",
    "confidentiality.data.firstSection.2": "Ces destinataires se situent parfois en dehors de l’Union Européenne. Dans ce cas, nous concevons des dispositions spécifiques pour assurer la protection complète de vos données.",
    "confidentiality.data.firstSection.3": "Vos informations personnelles nous aident à mieux vous connaître, et ainsi à vous proposer des solutions et services qui vous correspondent. Nous les conservons tout au long de la vie de votre contrat. Une fois ce dernier fermé, elles sont conservées pendant le délai de prescription.",
    "confidentiality.data.secondSection.1": "Vous gardez bien sûr tout loisir d’y accéder, de demander leur rectification, portabilité ou effacement et de vous opposer à leur utilisation. Vous pouvez également prendre contact avec le Délégué à la Protection des Données Personnelles (DPO) pour toute information ou contestation (loi 'Informatique et Libertés' du 6 janvier 1978). Pour cela, il vous suffit de nous écrire :",
    "confidentiality.data.secondSection.2": "- par mail à serviceclients@saretec.fr,",
    "confidentiality.data.secondSection.3": "- par courrier à l’adresse 9 Rue Georges Enesco, 94000 Créteil",
    "confidentiality.data.secondSection.4": "Vous pouvez également vous adresser à la CNIL.",
    "confidentiality.data.thirdSection": "Pour plus de détail, reportez-vous aux documents contractuels, notamment les dispositions générales ou notices d’information et les sites internet de Saretec. Protéger les victimes et nous protéger nous-mêmes est au cœur de la politique de maîtrise des risques de Saretec et de la lutte anti-fraude. Aussi, nous gardons la possibilité de vérifier ces informations et de saisir, si nécessaire, les autorités compétentes.",
    "confidentiality.thirdSection": "Pour plus de détail, reportez-vous aux documents contractuels, notamment les dispositions générales ou notices d’information et les sites internet de Saretec. Protéger les victimes et nous protéger nous-mêmes est au cœur de la politique de maîtrise des risques de Saretec et de la lutte anti-fraude. Aussi, nous gardons la possibilité de vérifier ces informations et de saisir, si nécessaire, les autorités compétentes.",
    "confidentiality.cookie.title": "Utilisation des cookies",
    "confidentiality.cookie.firstSection.1": "En quoi consistent les cookies ? Les cookies, un outil indispensable",
    "confidentiality.cookie.firstSection.2": "Il s’agit d’un simple fichier texte stocké temporairement ou définitivement sur votre périphérique fixe ou mobile (mais aussi dans certains cas, dans votre navigateur web) à la demande du serveur du site sur lequel vous naviguez. Il est renvoyé au site web concerné pour lui permettre de vous reconnaître lors de vos visites ultérieures.",
    "confidentiality.cookie.firstSection.3": "Mal connu des internautes, le cookie est utile, voire indispensable pour la navigation sur internet car il permet aux éditeurs de sites internet :",
    "confidentiality.cookie.firstSection.4": "✓ d’améliorer la performance de leur site",
    "confidentiality.cookie.firstSection.5": "✓ de proposer une meilleure expérience de navigation aux internautes.",
    "confidentiality.cookie.firstSection.6": "Il permet en effet de retracer l’historique de consultation des pages du site visité, et cela, sur tous les supports existants : mobiles, tablettes ou ordinateur. En apprenant à connaitre le comportement des internautes sur ses sites, le groupe Saretec pourra :",
    "confidentiality.cookie.firstSection.7": "✓ établir des statistiques fiables de fréquentation",
    "confidentiality.cookie.firstSection.8": "✓ mieux comprendre leurs besoins",
    "confidentiality.cookie.firstSection.9": "✓ sécuriser ses procédures",
    "confidentiality.cookie.firstSection.10": "✓ mémoriser les informations remplies sur les parcours de souscription",
    "confidentiality.cookie.firstSection.11": "✓ vous permettre de vous rendre dans votre espace personnel.",
    "confidentiality.cookie.secondSection.1": "Quels types de cookies utilisons-nous ?",
    "confidentiality.cookie.secondSection.2": "Comme la plupart des sites internet, nous utilisons des cookies. En poursuivant la navigation sur notre site vous avez consenti à l’usage de l’ensemble des 4 types de cookies suivants :",
    "confidentiality.cookie.thirdSection.1": "✓ Les cookies fonctionnels",
    "confidentiality.cookie.thirdSectionn.2": "Sans eux, vous ne pourriez tout simplement pas naviguer sur Saretec.fr et en utiliser les différentes fonctionnalités. Ils permettent notamment d’accéder à son espace personnel et de consulter l’ensemble des données qui y sont stockées : devis sauvegardés, contrat(s) en cours et documentation. Ils nous permettent en outre de garder en mémoire certaines des informations que vous nous communiquez, par exemple lors d’une demande de devis. Ils permettent d’archiver vos préférences de navigation sur Saretec.fr et de garder ainsi en mémoire les différents paramètres que vous avez choisis lors de votre dernière visite comme la version du site consultée (mobile, tablette ou ordinateur). ✓ Les cookies de performance et de mesure d'audience Ces cookies recueillent anonymement un certain nombre d’informations lors de vos visites sur Saretec.fr, comme la manière dont vous êtes arrivé sur le site ou encore les pages que vous fréquentez le plus souvent. L'objectif de ces cookies est de mesurer la fréquentation de nos pages et de nous permettre d’analyser et de mieux comprendre les comportements des internautes sur le site en vue d’en améliorer les services, le contenu et le fonctionnement.",
    "confidentiality.cookie.fourthSection.1": "✓ Les cookies de publicité",
    "confidentiality.cookie.fourthSection.2": "Ces cookies ont pour objectif de cibler au mieux nos campagnes publicitaires sur internet. Ils nous permettent, directement ou via nos partenaires (Google, Facebook ...), de vous proposer des bannières publicitaires personnalisées en fonction de votre expérience de navigation sur notre site. Exemple : imaginons que vous avez entré une requête concernant l’assurance auto dans un moteur de recherche et que vous avez ainsi navigué dans la fiche produit correspondante sur notre site. Dans ce cas, lors de vos visites ultérieures sur les sites de nos partenaires, il est possible qu’une bannière présentant notre assurance auto vous soit proposée. Ainsi, en fonction des pages fréquentées par nos internautes, nous personnalisons nos bannières afin de leur proposer les contenus et les offres les plus adaptés et les plus pertinents. Les cookies ne tracent pas les adresses IP des utilisateurs.",
    "confidentiality.cookie.fifthSection.1": "✓ Les cookies des réseaux sociaux",
    "confidentiality.cookie.fifthSection.2": "Saretec.fr propose à l’internaute des boutons de partage de contenu via les réseaux sociaux. Ces boutons sont mis à disposition par les réseaux sociaux et font appel aux cookies afin de suivre la navigation des internautes. Même si l’internaute ne clique pas sur les boutons 'réseaux sociaux' disponibles sur Saretec.fr, les réseaux sociaux concernés sont susceptibles d’identifier sa navigation sur le site. Les cookies sont déposés directement par les réseaux sociaux. Par suite, les choix de l’internaute concernant le dépôt de ces cookies doivent également s’effectuer auprès des réseaux sociaux. A cette fin, vous êtes invité à consulter directement ces sites et leur politique de protection des données personnelles. Si vous souhaitez en savoir plus sur les cookies, leur fonctionnement et leur utilisation, vous pouvez vous rendre sur le site de la CNIL : CNIL, les outils pour maîtriser les cookies",
    "confidentiality.cookie.sixthSection.1": "Comment gérer les cookies ?",
    "confidentiality.cookie.sixthSection.2": "La gestion des cookies se paramètre dans son navigateur web",
    "confidentiality.cookie.sixthSection.3": "Vous pouvez ainsi paramétrer votre navigateur de façon qu’il :",
    "confidentiality.cookie.sixthSection.4": "- refuse systématiquement l’envoi de cookies",
    "confidentiality.cookie.sixthSection.5": "- n’accepte l’envoi de cookies que pour certains sites considérés comme étant 'de confiance'",
    "confidentiality.cookie.sixthSection.6": "- n’accepte l’envoi de cookies que pour le site sur lequel vous êtes en train de naviguer.",
    "confidentiality.cookie.sixthSection.7": "Notre conseil : si vous souhaitez bénéficier de l’ensemble des fonctionnalités existantes sur notre site, veuillez ne pas désactiver les cookies, votre expérience de navigation en deviendrait alors moins fluide.",
    "confidentiality.info.title": "Informations sur les produits et services",
    "confidentiality.info.1": "Ces informations sont susceptibles d’évoluer à tout moment sans préavis.",
    "confidentiality.info.2": "Saretec s’efforcera d'assurer leur mise à jour au fur et à mesure des évolutions apportées à ses produits et services, ainsi que des évolutions législatives ou réglementaires éventuelles. Saretec s’engage à vous remettre sur simple demande, les dispositions contractuelles en vigueur afférentes à ces produits et services.",
    "confidentiality.info.3": "Saretec ne pourra en aucun cas être tenu pour responsable en cas de retard de mise à jour, d'erreur ou d'omission quant au contenu des pages du site. Saretec ne saurait être tenu pour responsable des dommages directs et indirects résultant de l'accès ou de l'usage du site.",
    "confidentiality.info.4": "Le site Saretec contient par ailleurs des liens hypertextes orientant l’internaute vers d’autres sites internet.",
    "confidentiality.info.5": "Saretec n’est pas responsable du contenu des informations accessibles via son site au moyen de ces liens hypertextes. Ainsi Saretec ne peut garantir leur exactitude, mise à jour, caractère actuel et loyal, qui relèvent de la seule et entière responsabilité des personnes auteurs ou propriétaires de ces sites.",
    "confidentiality.info.6": "Les simulateurs mis en ligne sur le site Saretec ont été conçus pour vous fournir des informations ou des valeurs prévisionnelles, à titre indicatif. Les estimations sont calculées sur la base des données de simulation communiquées par l’internaute au jour de leur saisie et de la réglementation en vigueur à cette date, mais ne sauraient constituer un indicateur fiable, ni servir de justificatif vis-à-vis de toute Administration.",
    "confidentiality.info.7": "Vous êtes invité à vous rapprocher d’un Conseiller Saretec pour toute demande de précision, communication d’informations complémentaires ou conseils sur les produits et services d’Saretec présentés sur le site.",
    "confidentiality.warning.title": "Avertissement",
    "confidentiality.warning.1": "Les produits et services présentés sur le site sont destinés aux résidents français.",
    "confidentiality.warning.2": "Le site et son contenu font l'objet d'une protection légale au titre de la propriété littéraire et artistique (droits d'auteurs, des interprètes...) et de la propriété industrielle (marques, dessins...).",
    "confidentiality.warning.3": "Il est interdit à tout internaute de copier ou reproduire, par numérisation et tout autre moyen, sur quelque support que ce soit, tout ou partie du contenu du site, qu'il soit textuel, graphique ou sonore, sauf pour son usage propre. Le site indique en particulier à l'internaute les parties du contenu du site qu'il est autorisé à télécharger pour les besoins de sa relation avec Saretec.",
    "confidentiality.warning.4": "Le non-respect de ces règles peut engager la responsabilité civile et pénale du contrevenant.",
    "confidentiality.contact.title": "Coordonnées des organismes pour l'affiliation des agents",
    "confidentiality.contact.1": "Organisme d'immatriculation : ORIAS, site internet www.orias.fr.",
    "confidentiality.contact.2": "Autorité de contrôle prudentiel et de résolution : ACPR - 4 place de Budapest - CS 92459 - 75436 Paris Cedex 09.",
    "confidentiality.demarchage.title": "Démarchage téléphonique",
    "confidentiality.demarchage.1": "Si vous ne souhaitez pas faire l’objet de prospection commerciale par téléphone, vous pouvez gratuitement vous inscrire sur une liste d’opposition au démarchage téléphonique.",

    "legal.title": "Éditeur du site :",
    "legal.section.1": "SARETEC DEVELOPPEMENT, société anonyme à conseil d’administration",
    "legal.section.2": "Au capital social de 1 356 080,00 euros",
    "legal.section.3": "Situé au 9-11 Rue Georges Enesco 94000 Créteil",
    "legal.section.4": "Inscrite au Registre du commerce et des sociétés de Créteil sous le numéro 352 603 575",
    "legal.section.5": "Numéro de téléphone : 01 49 56 84 00",
    "legal.section.6": "Adresse de courrier électronique : contact@reconstruireensemble62.fr",
    "legal.section.7": "Directeur de la publication :",
    "legal.section.8": "Monsieur Jean-Vincent RAYMONDIS, Président du Conseil d’administration",

    "contact.title": "Demande de contact",
    "contact.description": "Nous vous répondrons dans les plus brefs délais",
    "contact.alert.success": "Votre message nous a bien été transmis",
    "contact.btn": "Envoyer la demande",
    "contact.form.artisan": "Je suis artisan",
    "contact.form.sinistre": "Je suis sinistré",
    "contact.form.email": "Adresse e-mail*",
    "contact.form.subject": "Sujet*",
    "contact.form.message": "Demande*",
    "contact.error.fields": "Tous les champs sont requis",
    "contact.error.captcha": "Veuillez valider le Captcha",
  },
  es: {
    "home.layout.contact": "Contáctanos",
    "home.layout.signIn": "Acceso para reparadores",
    "home.layout.account": "Mi cuenta",
    "home.hero.title": "Reconstruir Juntos 46",
    "home.hero.description.1": "Bienvenido a tu solución de contacto entre siniestrados y artesanos",
    "home.hero.description.2": "Este servicio estará disponible hasta el 30 de junio de 2025.",
    "home.hero.btns.1": "Soy siniestrado",
    "home.hero.btns.2": "Soy una empresa de reparación",
    "home.section1.title": "Soy siniestrado, ¿cómo funciona?",
    "home.section1.description.1": "Es muy simple: haces una solicitud de reparación y un artesano se ofrece para realizarla.",
    "home.section1.description.2": "Te enviaremos sus datos de contacto para que puedas comunicarte con él. ¡A ti te toca el siguiente paso!",
    "home.section1.description.3": "El servicio estará disponible hasta el 30 de junio de 2025.",
    "home.section2.title": "Soy una empresa de reparación, ¿cómo funciona?",
    "home.section2.description.1": "Descubre las necesidades de reparación de los habitantes de Valencia. ¡Simplemente regístrate y postúlate para las solicitudes de trabajo enviadas por los siniestrados!",
    "home.section2.description.2": "El servicio estará disponible hasta el 30 de junio de 2025.",
    "home.section3.title": "El servicio Reconstruir Juntos es 100 % gratuito",
    "home.section3.description.1": "Gratis para los siniestrados que buscan artesanos para realizar sus reparaciones.",
    "home.section3.description.2": "Gratis para los artesanos que desean conocer las necesidades de reparación en un área determinada.",
    "home.section3.description.3": "El servicio estará disponible hasta el 30 de junio de 2025.",
    "home.footer.links.1": "Aviso legal",
    "home.footer.links.2": "Política de privacidad",
    "home.footer.links.3": "Contáctanos",
    "home.footer.copyright": "Copyright © Keywiiz by ",

    "breadcrumb.home": "Inicio",
    "breadcrumb.createDossier": "Creación de solicitud",
    "breadcrumb.passwordLost": "¿Olvidaste tu contraseña?",
    "breadcrumb.reparateur": "Reparador",
    "breadcrumb.detail": "detalles",
    "breadcrumb.dossiers": "Expedientes",
    "breadcrumb.dashboard": "Panel de control",
    "breadcrumb.artisan": "Perfil del reparador",
    "breadcrumb.confidentiality": "Perfil del reparador",
    "breadcrumb.legal": "Aviso legal",
    "breadcrumb.signUpRepair": "Registro reparador",

    "createDossier.title": "Registro de mi solicitud de trabajos",
    "createDossier.section1.title": "Mis datos de contacto",
    "createDossier.section1.info": "Esta solución está actualmente destinada a los afectados del departamento de Valencia",
    "createDossier.section2.title": "Mis daños",
    "createDossier.section2.description": "Por favor, complete la información sobre su solicitud de trabajos",
    "createDossier.validateBtn": "Validar mi solicitud de trabajos",
    "createDossier.error.data": "Completo mi solicitud de trabajo",
    "createDossier.error.captcha": "Completo mi solicitud de trabajo",
    "createDossier.alert.success": "Su expediente ha sido creado pero aún no está activo. Por favor, consulte su correo electrónico y haga clic en el enlace para activarlo.",

    "dossierDamage.fields.logement": "¿Vivienda habitable?",
    "dossierDamage.fields.logement.oui": "Sí",
    "dossierDamage.fields.logement.non": "No",
    "dossierDamage.fields.nombrePieces": "Número de habitaciones dañadas",
    "dossierDamage.fields.expertise": "¿Se ha realizado una evaluación?",
    "dossierDamage.fields.expertise.oui": "Sí",
    "dossierDamage.fields.expertise.non": "No",
    "dossierDamage.fields.montantEstime": "Monto estimado de los trabajos (presupuesto o evaluación del perito)",
    "dossierDamage.fields.travaux": "Los trabajos incluyen",
    "dossierDamage.fields.description": "Descripción de los daños",
    "dossierDamage.fields.scoreAmplitude": "La magnitud de los daños es",
    "dossierDamage.fields.scoreAmplitude.1": "Baja",
    "dossierDamage.fields.scoreAmplitude.2": "Media",
    "dossierDamage.fields.scoreAmplitude.3": "Alta",

    "dossierInfo.fields.name": "Nombre o razón social",
    "dossierInfo.fields.postalCode": "Código postal",
    "dossierInfo.fields.email": "Correo electrónico",
    "dossierInfo.fields.phone": "Número de teléfono",

    "dossierArtisan.noDossier": "No hay expedientes disponibles.",
    "dossierArtisan.dossier": "Expedientes disponibles",
    "dossierArtisan.candidature": "Mis candidaturas",
    "dossierArtisan.error.noCertificat.1": "Por favor, importe su certificado de seguro del año en curso para completar su perfil.",
    "dossierArtisan.error.noCertificat.2": "Podrá comenzar a posicionarse en los expedientes disponibles una vez que un administrador haya validado su certificado de seguro. Se le enviará una notificación por correo electrónico.",
    "dossierArtisan.error.certificatWait": "Podrá comenzar a posicionarse en los expedientes disponibles una vez que un administrador haya validado su certificado de seguro. Se le enviará una notificación por correo electrónico.",
    "dossierArtisan.info.1": "Los expedientes listados aquí requieren al menos una de las competencias que ha registrado.",
    "dossierArtisan.info.2": "Una competencia aparece en gris si no forma parte de su lista.",
    "dossierArtisan.contact.1": "Ningún artesano interesado",
    "dossierArtisan.contact.2": "1 artesano interesado",
    "dossierArtisan.contact.3": "artesanos interesados",
    "dossierArtisan.chip.sent": "Candidatur enviada",
    "dossierArtisan.chip.closed": "Expediente cerrado",
    "dossierArtisan.tooltip": "Candidatura bloqueada, certificado(s) en espera de validación",
    "dossierArtisan.professionsChip.title": "Competencias necesarias",
    "dossierArtisan.createdAt": "Creado el",
    "dossierArtisan.updatedAt": "Actualizado el",
    "dossierArtisan.btn.candidate": "Aplicar al expediente",

    "dossierAdmin.noArtisan": "Ningún artesano interesado",
    "dossierAdmin.oneArtisan": "1 artesano interesado",
    "dossierAdmin.artisans": "artesanos interesados",
    "dossierAdmin.detail": "Detalles del expediente",
    "dossierAdmin.skills": "Competencias necesarias",
    "dossierAdmin.createdAt": "Creado el",
    "dossierAdmin.updatedAt": "Actualizado el",
    "dossierAdmin.contact.name": "Nombre",
    "dossierAdmin.contact.phone": "Número de teléfono",
    "dossierAdmin.contact.address": "Dirección",
    "dossierAdmin.contact.detail": "Detalles del reparador",
    "dossierAdmin.contact.skills": "Competencias",
    "dossierAdmin.status.title": "Estado del expediente",
    "dossierAdmin.status.initiated": "Iniciado por la empresa",
    "dossierAdmin.status.contacted": "Empresa contactada",
    "dossierAdmin.status.devisValidated": "Presupuesto validado",
    "dossierAdmin.status.aborted": "Contacto cancelado",
    "dossierAdmin.error.invalidId": "Identificador de expediente no válido",
    "dossierAdmin.edit.info": "Datos del expediente",
    "dossierAdmin.edit.damage": "Daños en el expediente",
    "dossierAdmin.detail.contact": "Contactos del expendiente",
    "dossierAdmin.detail.noContact": "Ningún contacto en este expediente",
    "dossierAdmin.filter.title": "Estados",
    "dossierAdmin.filters.all": "Todos",
    "dossierAdmin.filters.waiting": "En espera",
    "dossierAdmin.filters.opened": "Expedientes abiertos",
    "dossierAdmin.filters.solved": "Expedientes solucionados",

    "dossierRecap.title": "Mi solicitud de trabajosx",
    "dossierRecap.subtitle": "Resumen de mi solicitud",
    "dossierRecap.contact.title.1": "Ningún reparador le ha contactado, tenga un poco de paciencia",
    "dossierRecap.contact.title.2": "Un reparador disponible",
    "dossierRecap.contact.title.3": "Reparadores disponibles",
    "dossierRecap.content.dossier": "Expediente n.º",
    "dossierRecap.content.habitation.title": "Realojamiento",
    "dossierRecap.content.habitation.oui": "Sí",
    "dossierRecap.content.habitation.non": "No",
    "dossierRecap.content.damagedRooms": "Habitaciones dañadas",
    "dossierRecap.content.amplitude": "Magnitud",
    "dossierRecap.content.amount.title": "Monto de la valoración",
    "dossierRecap.content.amount.suffix": "€",
    "dossierRecap.content.description": "Descripción de los daños",
    "dossierRecap.content.professions": "Los trabajos conciernen",
    "dossierRecap.content.btn.edit": "Modificar mi solicitud",
    "dossierRecap.content.btn.close": "Cerrar mi solicitud",

    "contact.btn.initiated": "Me puse en contacto con la empresa",
    "contact.btn.called": "Me puse en contacto con la empresa",
    "contact.btn.accepted": "He aceptado el presupuesto",
    "contact.btn.canceled": "Contacto cancelado",
    "contact.name": "Nombre",
    "contact.phone": "Número de teléfono",
    "contact.address": "Dirección",
    "contact.skills": "Competencias",

    "artisanActivation.loading": "Activación de su cuenta...",
    "artisanActivation.error.title": "Este enlace de activación ya está en uso o es incorrecto",
    "artisanActivation.error.btn": "Conexión",
    "artisanActivation.default.title": "Su cuenta ha sido activada",
    "artisanActivation.default.btn": "Conexión",

    "artisanAdmin.skills": "Competencias",
    "artisanAdmin.error": "Ha ocurrido un error",
    "artisanAdmin.contact": " Ha ocurrido un error",
    "artisanAdmin.updateProfile": "Actualizar el perfil",
    "artisanAdmin.certificates": "Certificados",
    "artisanAdmin.alert.success": "Perfil actualizado",
    "artisanAdmin.filters.status": "Estados",
    "artisanAdmin.filters.all": "Todos",
    "artisanAdmin.filters.sent": "Certificados pendientes de validación",
    "artisanAdmin.filters.validated": "Certificados validados",
    "artisanAdmin.certificat.validate": "Validar",
    "artisanAdmin.certificat.deny": "Rechazar",
    "artisanAdmin.certificat.download": "Descargar",
    "artisanAdmin.certificat.status.denied": "Rechazado",
    "artisanAdmin.certificat.status.validated": "Validar",
    "artisanAdmin.certificat.status.waiting": "En espera de validación",

    "passwordLost.title": "Reconstruir Juntos 46",
    "passwordLost.subtitle": "Ingrese su correo electrónico para recibir un enlace de restablecimiento de su contraseña.",
    "passwordLost.mail": "Correo electrónico",
    "passwordLost.btn": "Validar",
    "passwordLost.error.fields": "Por favor, complete todos los campos",
    "passwordLost.error.captcha": "Por favor, valide el Captcha",
    "passwordLost.error.default": "Ha ocurrido un error",

    "login.email": "Correo electrónico",
    "login.password": "Contraseña",
    "login.admin.identifiant": "Identificador",
    "login.admin.otp": "OTP",

    "resetPassword.title": "Restablecer contraseña",
    "resetPassword.form.password": "Contraseña",
    "resetPassword.form.password.error.1": "Las contraseñas no coinciden",
    "resetPassword.form.password.error.2": "Campo obligatorio",
    "resetPassword.form.confirmation": "Confirmación de la contraseña",
    "resetPassword.btn.update": "Actualizar",
    "resetPassword.success.alert.1": "Su contraseña ha sido actualizada",
    "resetPassword.success.alert.2": "Ahora puedes",
    "resetPassword.success.alert.3": "conectarse",
    "resetPassword.success.alert.4": "con su nueva contraseña.",
    "resetPassword.error.fields": "Por favor, complete los campos obligatorios.",
    "resetPassword.error.captcha": "Por favor, valide el Captcha",

    "inscription.form.field.error": "Campo obligatorio",
    "inscription.form.name": "Su cuenta ha sido creada. Por favor, compruebe su correo electrónico para completar su registro",
    "inscription.form.name.error": "Nombre no válido",
    "inscription.form.siret": "Número de Identificación Fiscal (NIF)",
    "inscription.form.siret.error": "Número de Identificación Fiscal no válido",
    "inscription.form.email": "Correo electrónico",
    "inscription.form.email.error": "Correo electrónico no válido",
    "inscription.form.phone": "Número de teléfono",
    "inscription.form.phone.error": "Número de teléfono no válido",
    "inscription.success.alert": "Su cuenta ha sido creada. Por favor, compruebe su correo electrónico para completar su registro",
    "inscription.error.fields": "Por favor, complete los campos obligatorios.",
    "inscription.error.captcha": "Por favor, valide el Captcha",
    "inscription.accountExist": "¿Ya tiene una cuenta?",
    "inscription.login": "Conexión",

    "administration.title": "Su cuenta no permite la administración de RJ46",
    "administration.description": "Por favor, utilice una cuenta SUPER_ADMIN",
    "administration.dossiers": "Expedientes",
    "administration.reparateurs": "Reparadores",
    "administration.logout": "Desconectar",

    "loginAdmin.title": "RJ46 Admin",
    "loginAdmin.login": "Conexión",

    "loginArtisan.title": "Reconstruir Juntos 46",
    "loginArtisan.noAccount": "¿Aún no tiene cuenta?",
    "loginArtisan.signup": "inscripción",
    "loginArtisan.login": "Conexión",
    "loginArtisan.lostPassword": "¿Ha olvidado su contraseña?",
    "loginArtisan.error.fields": "Por favor, complete todos los campos",
    "loginArtisan.error.captcha": "Por favor, valide el Captcha",

    "artisan.infos": "Mis datos de contacto",
    "artisan.skills": "Mis competencias",
    "artisan.certificates": "Mis certificados",
    "artisan.alert.error.first": "Por favor, actualice las competencias de su empresa para acceder a la lista de expedientes",
    "artisan.alert.error.second.1": "Por favor, importe su certificado de seguro del año en curso para completar su perfil.",
    "artisan.alert.error.second.2": "Podrá empezar a posicionarse en los expedientes disponibles en cuanto un administrador haya validado su certificado de seguro. Se le notificará por correo electrónico.",
    "artisan.alert.error.third": "Podrá empezar a posicionarse en los expedientes disponibles en cuanto un administrador haya validado su certificado de seguro. Se le notificará por correo electrónico.",
    "artisan.alert.profilUpdated": "Perfil actualizado",
    "artisan.updateProfile": " Actualizar mi perfil",
    "artisan.recap.number": "Artesano n°",
    "artisan.recap.name": "Nombre",
    "artisan.recap.siret": "Número de Identificación Fiscal (NIF)",
    "artisan.recap.email": "Correo electrónico",
    "artisan.recap.phone": "Número de teléfono",
    "artisan.recap.address": "Dirección",

    "certificat.title.1": "Certificado de seguro",
    "certificat.title.2": "Otros certificados",
    "certificat.item.status.denied": "Rechazado",
    "certificat.item.status.validated": "Validado",
    "certificat.item.status.default": "En espera de validación",
    "certificat.item.download": "Descargar",
    "certificat.item.validate": "Validar",
    "certificat.item.deny": "Rechazar",
    "certificat.item.delete": "Eliminar",
    "certificat.upload": "Descargar un certificado",

    "professions.peinture": "Pintura",
    "professions.carrelage": "Cerámica",
    "professions.parquet": "Parqué",
    "professions.menuiserie": "Carpintería",
    "professions.platrerie": "Yesería",
    "professions.plomberie": "Fontanería",
    "professions.electricite": "Electricidad",
    "professions.miroiterie": "Cristalería",
    "professions.charpente": "Carpintería de armar",
    "professions.maconnerie": "Albañilería",
    "professions.couverture": "Cubiertas",
    "professions.serrurerie": "Cerrajería",
    "professions.assechement": "Secado",
    "professions.travauxpublics": "Obra pública",

    "about.title": "Acerca de nosotros",
    "confidentiality.data.title": "Protección de datos personales",
    "confidentiality.data.firstSection.1": "Recopilamos sus datos personales y los utilizamos para la gestión de su solicitud y nuestra relación comercial. Estos datos están destinados prioritariamente a las empresas del Grupo SARETEC y a su intermediario de seguros, así como a los diferentes organismos y socios directamente implicados en su contrato.",
    "confidentiality.data.firstSection.2": "En ocasiones, estos destinatarios se encuentran fuera de la Unión Europea. En estos casos, implementamos medidas específicas para garantizar la protección total de sus datos.",
    "confidentiality.data.firstSection.3": "Sus datos personales nos ayudan a conocerle mejor y, de esta manera, ofrecerle soluciones y servicios que se ajusten a sus necesidades. Conservamos esta información durante la vigencia de su contrato. Una vez finalizado, los datos se mantendrán durante el plazo de prescripción legal.",
    "confidentiality.data.secondSection.1": "Por supuesto, usted tiene derecho a acceder a sus datos, solicitar su rectificación, portabilidad o eliminación, así como oponerse a su uso. También puede contactar con el Delegado de Protección de Datos Personales (DPO) para cualquier consulta o reclamación, conforme a la Ley Orgánica 3/2018 y el Reglamento General de Protección de Datos (RGPD). Para ello, puede escribirnos:",
    "confidentiality.data.secondSection.2": "- Por correo electrónico a serviceclients@saretec.fr",
    "confidentiality.data.secondSection.3": "- Por correo postal a la dirección: Calle Georges Enesco, 9-11, 94000 Créteil, Francia.",
    "confidentiality.data.secondSection.4": "También puede dirigirse a la Agencia Española de Protección de Datos (AEPD) a través de su página web oficial.",
    "confidentiality.data.thirdSection": "Para más detalles, consulte los documentos contractuales, en particular las disposiciones generales o los avisos informativos, así como los sitios web de Saretec. Proteger a las víctimas y protegernos a nosotros mismos está en el centro de la política de gestión de riesgos de Saretec y de la lucha contra el fraude. Por ello, nos reservamos la posibilidad de verificar esta información y, si es necesario, informar a las autoridades competentes.",
    "confidentiality.thirdSection": "Para más detalles, consulte los documentos contractuales, en particular las disposiciones generales o los avisos informativos, así como los sitios web de Saretec. Proteger a las víctimas y protegernos a nosotros mismos está en el centro de la política de gestión de riesgos de Saretec y de la lucha contra el fraude. Por ello, nos reservamos la posibilidad de verificar esta información y, si es necesario, informar a las autoridades competentes.",
    "confidentiality.cookie.title": "Uso de cookies",
    "confidentiality.cookie.firstSection.1": "¿Qué son las cookies? Las cookies, una herramienta indispensable",
    "confidentiality.cookie.firstSection.2": "Se trata de un archivo de texto simple que se almacena de forma temporal o permanente en su dispositivo fijo o móvil (y en algunos casos, en su navegador web) a petición del servidor del sitio que está visitando. Este archivo se envía de vuelta al sitio web correspondiente para permitir que lo reconozca en sus futuras visitas.",
    "confidentiality.cookie.firstSection.3": "Aunque los internautas no siempre las conocen bien, las cookies son útiles e incluso indispensables para la navegación en internet, ya que permiten a los editores de sitios web:",
    "confidentiality.cookie.firstSection.4": "✓ mejorar el rendimiento de su sitio,",
    "confidentiality.cookie.firstSection.5": "✓ ofrecer una mejor experiencia de navegación a los usuarios.",
    "confidentiality.cookie.firstSection.6": "De hecho, permiten rastrear el historial de las páginas consultadas en el sitio visitado, en cualquier soporte existente: móviles, tabletas u ordenadores. Al aprender sobre el comportamiento de los usuarios en sus sitios, el grupo Saretec puede:",
    "confidentiality.cookie.firstSection.7": "✓ establecer estadísticas fiables de visitas,",
    "confidentiality.cookie.firstSection.8": "✓ comprender mejor sus necesidades,",
    "confidentiality.cookie.firstSection.9": "✓ asegurar sus procedimientos,",
    "confidentiality.cookie.firstSection.10": "✓ memorizar la información introducida durante los procesos de suscripción,",
    "confidentiality.cookie.firstSection.11": "✓ permitirle acceder a su espacio personal.",
    "confidentiality.cookie.secondSection.1": "¿Qué tipos de cookies utilizamos?",
    "confidentiality.cookie.secondSection.2": "Como la mayoría de los sitios web, utilizamos cookies. Al continuar navegando en nuestro sitio, usted ha aceptado el uso de los siguientes cuatro tipos de cookies:",
    "confidentiality.cookie.thirdSection.1": "✓ Cookies funcionales",
    "confidentiality.cookie.thirdSectionn.2": "Sin ellas, no podría simplemente navegar por Saretec.fr ni utilizar sus diferentes funcionalidades. Permiten, por ejemplo, acceder a su espacio personal y consultar todos los datos almacenados allí: presupuestos guardados, contrato(s) en curso y documentación. Además, nos permiten recordar cierta información que nos proporciona, como cuando realiza una solicitud de presupuesto. Estas cookies archivan sus preferencias de navegación en Saretec.fr y memorizan los diferentes parámetros elegidos durante su última visita, como la versión del sitio consultada (móvil, tableta u ordenador).",
    "confidentiality.cookie.fourthSection.1": "✓ Cookies de publicidad",
    "confidentiality.cookie.fourthSection.2": "Estas cookies tienen como objetivo optimizar nuestras campañas publicitarias en internet. Nos permiten, directamente o a través de nuestros socios (Google, Facebook, etc.), mostrarle banners publicitarios personalizados basados en su experiencia de navegación en nuestro sitio. Ejemplo: imaginemos que ha buscado información sobre seguros de automóvil en un motor de búsqueda y ha consultado la página correspondiente en nuestro sitio. En este caso, durante sus visitas posteriores a los sitios de nuestros socios, es posible que se le muestre un banner publicitario sobre nuestro seguro de automóvil. Así, en función de las páginas visitadas por nuestros usuarios, personalizamos los banners para ofrecer los contenidos y las ofertas más adaptadas y relevantes. Cabe destacar que las cookies no rastrean las direcciones IP de los usuarios.",
    "confidentiality.cookie.fifthSection.1": "✓ Cookies de redes sociales",
    "confidentiality.cookie.fifthSection.2": "Saretec.fr ofrece a los usuarios botones para compartir contenido a través de redes sociales. Estos botones son proporcionados por las redes sociales y utilizan cookies para seguir la navegación de los usuarios. Incluso si el usuario no hace clic en los botones de \"redes sociales\" disponibles en Saretec.fr, las redes sociales correspondientes pueden identificar su navegación en el sitio. Las cookies son directamente colocadas por las redes sociales. Por lo tanto, las opciones del usuario en relación con estas cookies deben configurarse directamente en los sitios de las redes sociales. Para ello, se le invita a consultar dichos sitios y sus políticas de protección de datos personales. Si desea obtener más información sobre las cookies, su funcionamiento y uso, puede visitar el sitio de la CNIL: CNIL, las herramientas para controlar las cookies.",
    "confidentiality.cookie.sixthSection.1": "¿Cómo gestionar las cookies?",
    "confidentiality.cookie.sixthSection.2": "La gestión de cookies se configura en su navegador web.",
    "confidentiality.cookie.sixthSection.3": "Puede configurar su navegador de modo que:",
    "confidentiality.cookie.sixthSection.4": "- rechace sistemáticamente el envío de cookies",
    "confidentiality.cookie.sixthSection.5": "- acepte el envío de cookies solo para ciertos sitios considerados de confianza",
    "confidentiality.cookie.sixthSection.6": "- acepte el envío de cookies solo para el sitio que está visitando en ese momento",
    "confidentiality.cookie.sixthSection.7": "Nuestro consejo: Si desea disfrutar de todas las funcionalidades existentes en nuestro sitio, le recomendamos no desactivar las cookies, ya que su experiencia de navegación podría ser menos fluida.",
    "confidentiality.info.title": "Información sobre productos y servicios",
    "confidentiality.info.1": "Esta información está sujeta a cambios en cualquier momento y sin previo aviso.",
    "confidentiality.info.2": "Saretec se esforzará por actualizarla conforme a las modificaciones realizadas en sus productos y servicios, así como a los posibles cambios legislativos o reglamentarios. Saretec se compromete a proporcionar, previa solicitud, las disposiciones contractuales vigentes relacionadas con estos productos y servicios.",
    "confidentiality.info.3": "Saretec no podrá, en ningún caso, ser considerado responsable por retrasos en la actualización, errores u omisiones en el contenido de las páginas del sitio. Asimismo, Saretec no será responsable de los daños directos o indirectos resultantes del acceso o uso del sitio.",
    "confidentiality.info.4": "El sitio de Saretec también contiene enlaces de hipertexto que redirigen al usuario a otros sitios web.",
    "confidentiality.info.5": "Saretec no es responsable del contenido de la información accesible desde su sitio a través de estos enlaces de hipertexto. Por lo tanto, Saretec no puede garantizar la exactitud, actualización, vigencia o fiabilidad de dicha información, ya que estas características son de exclusiva responsabilidad de los autores o propietarios de estos sitios.",
    "confidentiality.info.6": "Los simuladores puestos a disposición en el sitio web de Saretec han sido diseñados para proporcionarle información o valores predictivos de manera indicativa. Las estimaciones se calculan en función de los datos de simulación proporcionados por el usuario en la fecha de su introducción y conforme a la normativa vigente en esa fecha. Sin embargo, estas estimaciones no deben considerarse un indicador confiable ni un justificante ante cualquier Administración.",
    "confidentiality.info.7": "Se le invita a contactar a un asesor de Saretec para cualquier consulta, solicitud de información adicional o asesoramiento sobre los productos y servicios ofrecidos por Saretec que se presentan en el sitio web.",
    "confidentiality.warning.title": "Advertencia",
    "confidentiality.warning.1": "Los productos y servicios presentados en el sitio están destinados exclusivamente a los residentes en España.",
    "confidentiality.warning.2": "El sitio y su contenido están protegidos legalmente bajo los derechos de propiedad literaria y artística (derechos de autor, derechos de los intérpretes...) y los derechos de propiedad industrial (marcas, diseños...).",
    "confidentiality.warning.3": "Queda estrictamente prohibido que cualquier usuario copie o reproduzca, por digitalización o cualquier otro medio, en cualquier formato, todo o parte del contenido del sitio, ya sea textual, gráfico o sonoro, salvo para uso personal. El sitio especifica al usuario las partes del contenido que está autorizado a descargar para los fines de su relación con Saretec.",
    "confidentiality.warning.4": "El incumplimiento de estas normas puede implicar la responsabilidad civil y penal del infractor.",
    "confidentiality.contact.title": "Datos de los organismos para la afiliación de agentes",
    "confidentiality.contact.1": "Organismo de registro: ORIAS, sitio web www.orias.fr.",
    "confidentiality.contact.2": "Autoridad de Control Prudencial y de Resolución: ACPR - 4 place de Budapest - CS 92459 - 75436 París Cedex 09.",
    "confidentiality.demarchage.title": "Llamadas de prospección telefónica",
    "confidentiality.demarchage.1": "Si no desea recibir llamadas de prospección comercial, puede inscribirse de forma gratuita en una lista de oposición al telemercadeo.",

    "legal.title": "Editor del sitio :",
    "legal.section.1": "SARETEC DEVELOPPEMENT, sociedad anónima con consejo de administración",
    "legal.section.2": "Con un capital social de 1.356.080,00 euros",
    "legal.section.3": "Domicilio social: Calle Georges Enesco 9-11, 94000 Créteil (Francia)",
    "legal.section.4": "Inscrita en el Registro Mercantil de Créteil con el número 352 603 575",
    "legal.section.5": "Número de teléfono: 01 49 56 84 00",
    "legal.section.6": "Correo electrónico: contacto@reconstruirjuntos46.com",
    "legal.section.7": "Director de publicación:",
    "legal.section.8": "Sr. Jean-Vincent RAYMONDIS, Presidente del Consejo de Administración",

    "contact.title": "Solicitud de contacto",
    "contact.description": "Le responderemos lo antes posible",
    "contact.alert.success": "Hemos recibido su mensaje",
    "contact.btn": "Enviar la solicitud",
    "contact.form.artisan": "Soy artesano",
    "contact.form.sinistre": "Soy siniestrado",
    "contact.form.email": "Correo electrónico*",
    "contact.form.subject": "Temas*",
    "contact.form.message": "Solicitud*",
    "contact.error.fields": "Todos los campos son obligatorios",
    "contact.error.captcha": "Por favor, valide el Captcha",
  }
}
