import React, { useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Box, Grid, Button } from '@mui/material'

import { RecapItem, ProfessionChips } from 'ui'
import { Contact, ContactStatus } from 'model'
import { useDossierParameters } from 'api/DossierApi'
import { updateContact } from 'api/ContactApi'

type TContactBlockProps = {
  contact: Contact
  updateLocalContact: (c: Contact) => void
}

const wrapperSx = { border: 1, borderRadius: 2, p: 2, mb: 1 }
const actionSx = { textAlign: 'right', mt: 2 }

export const ContactBlock = ({ contact, updateLocalContact }: TContactBlockProps) => {
  const { dossierKey } = useDossierParameters()
  const intl = useIntl()
  const {
    artisan: { nom, adresse, telephone, professions },
    dossier,
  } = contact

  const setStatus = useCallback(
    (status: ContactStatus) => () => {
      updateContact(contact.id, dossierKey, status).then(updateLocalContact)
    },
    [contact, dossierKey, updateLocalContact],
  )

  const actions =
    contact.status === 'Initie' ? (
      <Box sx={actionSx}>
        <Button
          sx={{ width: { xs: '100%', md: 'auto' } }}
          onClick={setStatus('Appel')}
          variant="outlined"
        >
          <FormattedMessage
            id={'contact.btn.initiated'}
            defaultMessage={'J\'ai contacté l\'entreprise'}
          />
        </Button>
      </Box>
    ) : contact.status === 'Appel' ? (
      <Box sx={actionSx}>
        <Button
          sx={{
            width: { xs: '100%', md: 'auto' },
            mr: { xs: 0, md: 2 },
            mb: { xs: 2, md: 0 },
          }}
          onClick={setStatus('Initie')}
          variant="contained"
        >
          <FormattedMessage
            id={'contact.btn.called'}
            defaultMessage={'J\'ai contacté l\'entreprise'}
          />
        </Button>
        <Button
          sx={{ width: { xs: '100%', md: 'auto' } }}
          onClick={setStatus('Valide')}
          color="success"
          variant="outlined"
        >
          <FormattedMessage
            id={'contact.btn.accepted'}
            defaultMessage={'J\'ai accepté le devis'}
          />
        </Button>
      </Box>
    ) : contact.status === 'Valide' ? (
      <Box sx={actionSx}>
        <Button
          sx={{ width: { xs: '100%', md: 'auto' } }}
          onClick={setStatus('Appel')}
          color="success"
          variant="contained"
        >
          <FormattedMessage
            id={'contact.btn.accepted'}
            defaultMessage={'J\'ai accepté le devis'}
          />
        </Button>
      </Box>
    ) : (
      <Box sx={actionSx}>
        <Button color="info" variant="outlined">
        </Button>
      </Box>
    )

  return (
    <Box sx={wrapperSx}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <RecapItem
            title={intl.formatMessage({ id: 'contact.name', defaultMessage: 'Nom' })}
            value={nom}
            inline
          />
          <RecapItem
            title={intl.formatMessage({ id: 'contact.phone', defaultMessage: 'Téléphone' })}
            value={telephone}
            inline
          />
        </Grid>
        <Grid item xs={6}>
          {adresse && <RecapItem title={intl.formatMessage({ id: 'contact.address', defaultMessage: 'Adresse' })} value={adresse.toString()} inline />}
        </Grid>
        <Grid item xs={12}>
          <ProfessionChips
            title={intl.formatMessage({ id: 'contact.skills', defaultMessage: 'Compétences' })}
            professions={professions}
            reference={dossier.professions}
          />
        </Grid>
      </Grid>
      {actions}
    </Box>
  )
}
export default ContactBlock
