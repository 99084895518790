import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Grid, Typography, Box, Button } from '@mui/material'

import firstBanner from 'assets/HP1.png'
import secondBanner from 'assets/HP2.png'
import thirdBanner from 'assets/HP3.png'
import fourthBanner from 'assets/HP4.png'
import {FormattedMessage} from 'react-intl'

const pickerSx = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
  textAlign: 'center',
}

const titleDescriptionLeftSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  order: { xs: 2, sm: 1 },
}

const imageBannerRightSx = {
  order: { xs: 2, sm: 2 },
}

const titleDescriptionRightSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  order: { xs: 1, sm: 2 },
}

const imageBannerLeftSx = {
  order: { xs: 2, sm: 1 },
}

const HomePage = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        gap: 12.5,
        boxSizing: 'border-box',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '0 16px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={pickerSx}>
            <Typography component="h1" variant="h1" gutterBottom>
              <FormattedMessage
                id={'home.hero.title'}
                defaultMessage={'Reconstruire Ensemble 62'}
              />
            </Typography>
            <Typography component="span" variant="h3" sx={{ marginBottom: 2.4 }}>
              <FormattedMessage
                id={'home.hero.description.1'}
                defaultMessage={'Bienvenue sur votre solution de mise en contact entre sinistrés et artisans'}
              />
              <br />
              <FormattedMessage
                id={'home.hero.description.2'}
                defaultMessage={'Ce service est disponible jusqu\'au 30 juin 2024.'}
              />
            </Typography>

            <Box sx={{ padding: 2 }}>
              <Button
                variant="contained"
                size="large"
                sx={{ my: 1, width: 300, height: 47 }}
                fullWidth
                component={RouterLink}
                to="dossier/creation"
              >
                <Typography component="span" variant="button">
                  <FormattedMessage
                    id={'home.hero.btns.1'}
                    defaultMessage={'Je suis sinistré'}
                  />
                </Typography>
              </Button>
              <Button
                variant="text"
                size="large"
                sx={{
                  my: 1,
                  width: 300,
                  height: 47,
                  backgroundColor: '#FFFFFF',
                  color: '#102E46',
                  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.07)',
                }}
                fullWidth
                component={RouterLink}
                to="artisan/inscription"
              >
                <Typography component="span" variant="button">
                  <FormattedMessage
                    id={'home.hero.btns.2'}
                    defaultMessage={'Je suis une entreprise de réparation'}
                  />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={firstBanner}
            style={{ width: '100%', borderRadius: 24 }}
            alt="First Banner"
          />
        </Grid>
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} sx={imageBannerLeftSx}>
          <img
            src={secondBanner}
            style={{ width: '100%', borderRadius: 24 }}
            alt="Second Banner"
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={titleDescriptionRightSx}>
          <Typography component="h2" variant="h2">
            <FormattedMessage
              id={'home.section1.title'}
              defaultMessage={'Je suis sinistré, comment ça marche ?'}
            />
          </Typography>
          <Typography component="span" variant="body1">
            <FormattedMessage
              id={'home.section1.description.1'}
              defaultMessage={'C\'est très simple, vous déposez une demande de travaux, un artisan se propose pour les réaliser.'}
            />
            <br />
            <FormattedMessage
              id={'home.section1.description.2'}
              defaultMessage={'Nous vous transmettons ses coordonnées pour que vous puissiez échanger. A vous de jouer !'}
            />
            <br />
          </Typography>

          <Typography component="span" variant="body1">
            <FormattedMessage
              id={'home.section1.description.3'}
              defaultMessage={'Le service sera disponible jusqu\'au 30 juin 2024.'}
            />
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} sx={titleDescriptionLeftSx}>
          <Typography component="h2" variant="h2">
            <FormattedMessage
              id={'home.section2.title'}
              defaultMessage={'Je suis une entreprise de réparation, comment ça marche ?'}
            />
          </Typography>
          <Typography component="span" variant="body1">
            <FormattedMessage
              id={'home.section2.description.1'}
              defaultMessage={'Découvrez les besoins en travaux des habitants du Pas-de-Calais. Inscrivez-vous simplement et positionnez-vous sur les demandes de travaux transmises par les sinistrés !'}
            />
            <br />
            <FormattedMessage
              id={'home.section2.description.2'}
              defaultMessage={'Le service sera disponible jusqu\'au 30 juin 2024.'}
            />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={imageBannerRightSx}>
          <img
            src={thirdBanner}
            style={{ width: '100%', borderRadius: 24 }}
            alt="Third Banner"
          />
        </Grid>
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} sx={imageBannerLeftSx}>
          <img
            src={fourthBanner}
            style={{ width: '100%', borderRadius: 24 }}
            alt="Fourth Banner"
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={titleDescriptionRightSx}>
          <Typography component="h2" variant="h2">
            <FormattedMessage
              id={'home.section3.title'}
              defaultMessage={'Le service Reconstruire Ensemble 62 est 100% gratuit'}
            />
          </Typography>
          <Typography component="span" variant="body1">
            <FormattedMessage
              id={'home.section3.description.1'}
              defaultMessage={'Gratuit pour les sinistrés par la tempête Ciaran, qui cherchent des artisans pour réaliser leurs réparations.'}
            />
            <br />
            <FormattedMessage
              id={'home.section3.description.2'}
              defaultMessage={'Gratuit pour les artisans souhaitant connaitre les besoins en réparation dans un secteur donné.'}
            />
            <br />
            <FormattedMessage
              id={'home.section3.description.3'}
              defaultMessage={'Le service sera disponible jusqu\'au 30 juin 2024.'}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default HomePage
