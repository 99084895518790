import React, { useState, useCallback, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Typography, Box, Grid, Link, Button, Alert } from '@mui/material'
import ReCAPTCHA from 'react-google-recaptcha'

import type { ApiError, Artisan, FormState } from 'model'
import { isError } from 'model'
import ArtisanLoginForm from 'forms/ArtisanLoginForm'
import type { ArtisanLoginData } from 'forms/ArtisanLoginForm'
import { artisanLogin } from 'api/ArtisanApi'
import { useSession } from 'stores/SessionProvider'

import firstBanner from 'assets/HP1.png'

const emptyArtisanLoginData: ArtisanLoginData = { email: '', password: '' }

const loginSx = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
  textAlign: 'center',
}

type TLoginArtisanPageState = {
  formState: FormState
  errorMessage?: string
}

const LoginArtisanPage = () => {
  const [artisanLoginData, setArtisanLoginData] =
    useState<ArtisanLoginData>(emptyArtisanLoginData)
  const [state, setState] = useState<TLoginArtisanPageState>({ formState: 'ready' })
  const { setArtisan } = useSession()
  const navigate = useNavigate()
  const intl = useIntl()

  const { recaptchaEnabled, recaptchaApiKey } = APP_ENV
  const captchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    ev => {
      ev.preventDefault()
      const { email, password } = artisanLoginData
      const recaptcha = captchaRef?.current?.getValue() || ''
      if (email === '' || password === '') {
        setState(prev => ({
          ...prev,
          formState: 'error',
          errorMessage: intl.formatMessage({ id: "loginArtisan.error.fields", defaultMessage: 'Veuillez remplir tous les champs' }),
        }))
      } else if (recaptcha === '' && recaptchaEnabled) {
        setState(prev => ({
          ...prev,
          formState: 'error',
          errorMessage: intl.formatMessage({ id: "loginArtisan.error.captcha", defaultMessage: 'Veuillez valider le Captcha' }),
        }))
      } else {
        setState(prev => ({ ...prev, formState: 'loading', errorMessage: undefined }))
        artisanLogin({ email, password, recaptcha }).then((res: Artisan | ApiError) => {
          if (!isError(res)) {
            setState(prev => ({ ...prev, formState: 'success' }))
            setArtisan(() => res)
            navigate('/re62/')
          } else {
            setState(prev => ({ ...prev, formState: 'error', errorMessage: res.message }))
          }
        })
      }
    },
    [artisanLoginData, navigate, setArtisan, setState, captchaRef, recaptchaEnabled],
  )

  const { formState, errorMessage } = state
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box sx={loginSx}>
          <Typography variant="h1" component="h1" gutterBottom>
            <FormattedMessage
              id={"loginArtisan.title"}
              defaultMessage={"Reconstruire Ensemble 62"}
            />
          </Typography>
          <Typography variant="subtitle1" component="span" sx={{ mb: 6 }}>
            <FormattedMessage
              id={"loginArtisan.noAccount"}
              defaultMessage={"Pas encore de compte ?"}
            />{' '}
            <Link
              variant="subtitle1"
              component={RouterLink}
              to="/re62/artisan/inscription"
            >
              <FormattedMessage
                id={"loginArtisan.signup"}
                defaultMessage={"Inscription"}
              />
            </Link>
          </Typography>
          <form onSubmit={onSubmit}>
            <ArtisanLoginForm value={artisanLoginData} onChange={setArtisanLoginData} />
            {recaptchaEnabled && (
              <Box
                sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center' }}
              >
                <ReCAPTCHA sitekey={recaptchaApiKey} ref={captchaRef} />
              </Box>
            )}
            <Button
              fullWidth
              sx={{ my: 2 }}
              variant="contained"
              type="submit"
              color={formState === 'error' ? 'error' : 'primary'}
              disabled={formState === 'loading'}
            >
              <FormattedMessage
                id={"loginArtisan.login"}
                defaultMessage={"Connexion"}
              />
            </Button>
          </form>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/re62/artisan/passwordLost')}
            sx={{ cursor: 'pointer' }}
          >
            <FormattedMessage
              id={"loginArtisan.lostPassword"}
              defaultMessage={"Mot de passe oublié ?"}
            />
          </Link>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <img
          src={firstBanner}
          style={{ width: '100%', borderRadius: 24 }}
          alt="First Banner"
        />
      </Grid>
    </Grid>
  )
}
export default LoginArtisanPage
