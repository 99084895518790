import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'

import { Contact, Dossier } from 'model'
import { ExtendedArtisan } from 'model/Artisan'

import DossiersArtisanBlock from './DossierArtisanBlock'

type TDossiersArtisanListProps = {
  artisan: ExtendedArtisan
  dossiers: Dossier[]
  contacts: Contact[]
  addContact: (added: Contact) => void
}

const DossiersArtisanList = (props: TDossiersArtisanListProps) => {
  const { artisan, dossiers, contacts, addContact } = props
  if (dossiers.length === 0) {
    return <Typography variant="h3">
      <FormattedMessage
        id={'dossierArtisan.noDossier'}
        defaultMessage={'Aucun dossier disponible'}
      />
    </Typography>
  }
  const dossierContent = dossiers.map(dossier => {
    const contact = contacts.find(c => c.dossierId === dossier.id)
    return (
      <DossiersArtisanBlock
        key={dossier.id}
        contact={contact}
        dossier={dossier}
        artisan={artisan}
        addContact={addContact}
      />
    )
  })
  return <>{dossierContent}</>
}
export default DossiersArtisanList
