import React, { useCallback, useMemo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import { Dossier, DossierStatus } from 'model'

type Props = {
  dossiers: Dossier[]
  statusFilter: DossierStatus | undefined
  onChangeStatus: (status: DossierStatus | undefined) => void
}

const DossiersAdminFilter = ({ dossiers, statusFilter, onChangeStatus }: Props) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const status =
        event.target.value && event.target.value !== ''
          ? (event.target.value as DossierStatus)
          : undefined

      onChangeStatus(status)
    },
    [onChangeStatus],
  )
  const intl = useIntl()

  const statusFilterValue =
    statusFilter !== undefined && statusFilter.toString() !== '' ? statusFilter : ''

  const dossierCounts = useMemo(() => {
    return {
      Attentes: dossiers.filter(d => d.status === 'Attente').length,
      Ouverts: dossiers.filter(d => d.status === 'Ouvert').length,
      Resolus: dossiers.filter(d => d.status === 'Resolu').length,
    }
  }, [dossiers])

  return (
    <FormControl>
      <FormLabel id="status-radio-buttons-group">
        <FormattedMessage
          id={"dossierAdmin.filter.title"}
          defaultMessage={"Status"}
        />
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-radio-buttons-group"
        name="status-radio-buttons-group"
        row
        value={statusFilterValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value=""
          control={<Radio />}
          label={`${intl.formatMessage({ id: "dossierAdmin.filters.all", defaultMessage: "Tous" })} (${dossiers.length})`}
        />
        <FormControlLabel
          value="Attente"
          control={<Radio />}
          label={`${intl.formatMessage({ id: "dossierAdmin.filters.waiting", defaultMessage: "En attentes" })} (${dossierCounts.Attentes})`}
          disabled={dossierCounts.Attentes === 0}
        />
        <FormControlLabel
          value="Ouvert"
          control={<Radio />}
          label={`${intl.formatMessage({ id: "dossierAdmin.filters.opened", defaultMessage: "Ouverts" })} (${dossierCounts.Ouverts})`}
          disabled={dossierCounts.Ouverts === 0}
        />
        <FormControlLabel
          value="Resolu"
          control={<Radio />}
          label={`${intl.formatMessage({ id: "dossierAdmin.filters.solved", defaultMessage: "Résolus" })} (${dossierCounts.Resolus})`}
          disabled={dossierCounts.Resolus === 0}
        />
      </RadioGroup>
    </FormControl>
  )
}
export default DossiersAdminFilter
