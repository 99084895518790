import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Grid, TextField } from '@mui/material'

export type DossierInfos = {
  nom: string
  email: string
  telephone?: string
  codePostal: string
}

type TDossierInfosFormProps = {
  value: DossierInfos
  onChange: (data: Partial<DossierInfos>) => void
}

const DossierInfosForm = ({ value, onChange }: TDossierInfosFormProps) => {
  const { nom, email, telephone, codePostal } = value
  const setField = useCallback(
    (field: keyof DossierInfos, fieldValue: string) => onChange({ [field]: fieldValue }),
    [onChange],
  )
  const inputChange = useCallback(
    (field: keyof DossierInfos) => (ev: React.ChangeEvent<HTMLInputElement>) =>
      setField(field, ev.target.value),
    [setField],
  )
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="name"
          name="nom"
          required
          fullWidth
          id="nom"
          label={intl.formatMessage({ id: "dossierInfo.fields.name", defaultMessage: "Nom ou raison sociale"})}
          value={nom}
          onChange={inputChange('nom')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="postal-code"
          name="codePostal"
          required
          fullWidth
          id="codePostal"
          label={intl.formatMessage({ id: "dossierInfo.fields.postalCode", defaultMessage: "Code postal"})}
          value={codePostal}
          onChange={inputChange('codePostal')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="email"
          name="email"
          required
          fullWidth
          id="email"
          label={intl.formatMessage({ id: "dossierInfo.fields.email", defaultMessage: "Adresse e-mail"})}
          value={email}
          onChange={inputChange('email')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="telephone"
          fullWidth
          id="telephone"
          label={intl.formatMessage({ id: "dossierInfo.fields.phone", defaultMessage: "Numéro de téléphone"})}
          value={telephone}
          onChange={inputChange('telephone')}
        />
      </Grid>
    </Grid>
  )
}
export default DossierInfosForm
