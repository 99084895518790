import React, { useEffect, useCallback } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Box, Typography, Tabs, Tab } from '@mui/material'

import { isError } from 'model'
import { getCurrentUser, adminLogout } from 'api/AdminApi'
import { useSession } from 'stores/SessionProvider'

import LoginAdminPage from './LoginAdminPage'
import DossiersAdminPage from './dossiers/DossiersAdminPage'
import AdministrationArtisans from './artisans/AdministrationArtisans'

const Todo = ({ name }: { name: string }) => (
  <Typography variant="h3">Todo: {name}</Typography>
)

const Administration = () => {
  const { admin, setAdmin } = useSession()
  const location = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()

  useEffect(() => {
    if (admin === undefined) {
      getCurrentUser().then(res => {
        if (!isError(res)) setAdmin(_ => res)
      })
    }
  }, [admin, setAdmin])
  const onTabChange = useCallback(
    (_: React.SyntheticEvent, route: string) => {
      if (route === '/re62/admin') {
        adminLogout().then(() => {
          setAdmin(_ => undefined)
          navigate(route)
        })
      } else {
        navigate(route)
      }
    },
    [navigate, setAdmin],
  )

  if (admin === undefined) {
    return <LoginAdminPage />
  } else if (admin.profile !== 'SUPER_ADMIN') {
    return (
      <Box>
        <Typography variant="h3">
          <FormattedMessage
            id={"administration.title"}
            defaultMessage={"Votre compte ne permet pas l'administration RE62"}
          />
        </Typography>
        <Typography variant="h4">
          <FormattedMessage
            id={"administration.description"}
            defaultMessage={"Veuillez utiliser un compte SUPER_ADMIN"}
          />
        </Typography>
      </Box>
    )
  } else {
    const tabValue = location.pathname.split('/').slice(0, 4).join('/')
    return (
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          <Tabs value={tabValue} onChange={onTabChange} variant="fullWidth">
            <Tab label={intl.formatMessage({ id: "administration.dossiers", defaultMessage: "Dossiers" })} value="/re62/admin/dossiers" />
            <Tab label={intl.formatMessage({ id: "administration.reparateurs", defaultMessage: "Réparateurs" })} value="/re62/admin/artisans" />
            <Tab disabled sx={{ flexGrow: 1 }} />
            <Tab label={intl.formatMessage({ id: "administration.logout", defaultMessage: "Déconnexion" })} value="/re62/admin" />
          </Tabs>
        </Box>
        <Routes>
          <Route path="/" element={<Navigate to="/re62/admin/dossiers" />} />
          <Route path="/dossiers/*" element={<DossiersAdminPage />} />
          <Route path="/artisans/*" element={<AdministrationArtisans />} />
          <Route path="/logout" element={<Todo name="Logout" />} />
        </Routes>
      </Box>
    )
  }
}
export default Administration
