import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { Grid, TextField } from '@mui/material'

export type AdminLoginData = {
  user: string
  password: string
  otp: string
}

type TAdminLoginFormProps = {
  value: AdminLoginData
  onChange: (data: AdminLoginData) => void
}

const AdminLoginForm = ({ value, onChange }: TAdminLoginFormProps) => {
  const { user, password, otp } = value
  const setField = useCallback(
    (field: keyof AdminLoginData, fieldValue: string) =>
      onChange({ ...value, [field]: fieldValue }),
    [value, onChange],
  )
  const inputChange = useCallback(
    (field: keyof AdminLoginData): React.ChangeEventHandler<HTMLInputElement> =>
      ev =>
        setField(field, ev.target.value),
    [setField],
  )
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete="login"
          name="login"
          required
          fullWidth
          id="login"
          label={intl.formatMessage({ id: "login.admin.identifiant", defaultMessage: "Identifiant"})}
          value={user}
          onChange={inputChange('user')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="password"
          type="password"
          required
          fullWidth
          id="password"
          label={intl.formatMessage({ id: "login.password", defaultMessage: "Mot de passe"})}
          value={password}
          onChange={inputChange('password')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          name="otp"
          fullWidth
          id="otp"
          label={intl.formatMessage({ id: "login.admin.otp", defaultMessage: "OTP"})}
          value={otp}
          onChange={inputChange('otp')}
        />
      </Grid>
    </Grid>
  )
}
export default AdminLoginForm
