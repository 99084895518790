import React, { useCallback, useMemo } from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import { ExtendedArtisan } from 'model/Artisan'
import { CertificatArtisanStatus } from 'model/CertificatArtisan'

type Props = {
  artisans: ExtendedArtisan[]
  statusFilter: CertificatArtisanStatus | undefined
  onChangeStatus: (status: CertificatArtisanStatus | undefined) => void
}

const ArtisansAdminFilter = ({ artisans, statusFilter, onChangeStatus }: Props) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const status =
        event.target.value && event.target.value !== ''
          ? (event.target.value as CertificatArtisanStatus)
          : undefined

      onChangeStatus(status)
    },
    [onChangeStatus],
  )

  const statusFilterValue =
    statusFilter !== undefined && statusFilter.toString() !== '' ? statusFilter : ''
  const artisansCounts = useMemo(() => {
    return {
      Uploaded: artisans.filter(a => a.hasUploadedCertificatAssurance).length,
      Validated: artisans.filter(a => a.hasValidCertificatAssurance).length,
    }
  }, [artisans])
  const intl = useIntl()

  return (
    <FormControl>
      <FormLabel id="status-radio-buttons-group">
        <FormattedMessage
          id={"artisanAdmin.filters.status"}
          defaultMessage={"Status"}
        />
      </FormLabel>
      <RadioGroup
        aria-labelledby="status-radio-buttons-group"
        name="status-radio-buttons-group"
        row
        value={statusFilterValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value=""
          control={<Radio />}
          label={`${intl.formatMessage({ id: "artisanAdmin.filters.all", defaultMessage: "Tous"})} (${artisans.length})`}
        />
        <FormControlLabel
          value="Envoye"
          control={<Radio />}
          label={`${intl.formatMessage({ id: "artisanAdmin.filters.sent", defaultMessage: "Certificats en attentes de validation"})} (${artisansCounts.Uploaded})`}
          disabled={artisansCounts.Uploaded === 0}
        />
        <FormControlLabel
          value="Valide"
          control={<Radio />}
          label={`${intl.formatMessage({ id: "artisanAdmin.filters.valitated", defaultMessage: "Certificats validés"})} (${artisansCounts.Validated})`}
          disabled={artisansCounts.Validated === 0}
        />
      </RadioGroup>
    </FormControl>
  )
}
export default ArtisansAdminFilter
