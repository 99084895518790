import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink, useSearchParams, useMatch } from 'react-router-dom'
import { Typography, Box, Button } from '@mui/material'

import { activateArtisan } from 'api/ArtisanApi'
import { FormState, isError } from 'model'

const ArtisanActivationPage = () => {
  const [formState, setFormState] = useState<FormState>('loading')
  const [searchParams] = useSearchParams()
  const artisanMatch = useMatch('/re62/artisan/:artisanId/*')

  const artisanId = artisanMatch?.params?.artisanId || ''
  const artisanToken = searchParams.get('token') || ''

  useEffect(() => {
    if (artisanToken !== '') {
      activateArtisan(artisanId, artisanToken).then(res => {
        if (isError(res)) setFormState('error')
        else setFormState('success')
      })
    }
  }, [artisanId, artisanToken, setFormState])

  if (artisanId.length < 20) {
    // Loader while other page loads
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          ...
        </Typography>
      </Box>
    )
  } else if (formState === 'loading') {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          <FormattedMessage
            id={'artisanActivation.loading'}
            defaultMessage={'Activation de votre compte ...'}
          />
        </Typography>
      </Box>
    )
  } else if (formState === 'error') {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          <FormattedMessage
            id={'artisanActivation.error.title'}
            defaultMessage={'Ce lien d\'activation est déjà utilisé ou incorrect'}
          />
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/re62/artisan/login"
          sx={{ mt: 2 }}
        >
          <FormattedMessage
            id={'artisanActivation.error.btn'}
            defaultMessage={'Connexion'}
          />
        </Button>
      </Box>
    )
  } else {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          <FormattedMessage
            id={'artisanActivation.default.title'}
            defaultMessage={'Votre Compte a bien été activé'}
          />
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/re62/artisan/login"
          sx={{ mt: 2 }}
        >
          <FormattedMessage
            id={'artisanActivation.default.btn'}
            defaultMessage={'Connexion'}
          />
        </Button>
      </Box>
    )
  }
}
export default ArtisanActivationPage
