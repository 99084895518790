import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, Chip, Link } from '@mui/material'

import { RecapItem, ProfessionChips } from 'ui'
import { boxSx } from 'ui/ComponentsSx'
import type { Contact } from 'model'
import { contactStatuslibelles } from 'model'

type TDossierContactBlockProps = {
  contact: Contact
}

const statusSx = { textAlign: 'right' }

export const DossierContactBlock = ({ contact }: TDossierContactBlockProps) => {
  const {
    artisan: { id, nom, adresse, telephone, professions },
    dossier,
    status,
  } = contact
  const intl = useIntl()

  return (
    <Box sx={boxSx}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <RecapItem title={intl.formatMessage({ id:"dossierAdmin.contact.name", defaultMessage: "Nom" })} value={nom} inline />
          <RecapItem title={intl.formatMessage({ id:"dossierAdmin.contact.phone", defaultMessage: "Téléphone" })} value={telephone} inline />
          {adresse && <RecapItem title={intl.formatMessage({ id:"dossierAdmin.contact.address", defaultMessage: "Adresse" })} value={adresse.toString()} inline />}
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Box sx={statusSx}>
            <Chip label={contactStatuslibelles(intl)[status]} />
          </Box>
          <Link underline="none" component={RouterLink} to={`/re62/admin/artisans/${id}`}>
            <FormattedMessage
              id={"dossierAdmin.contact.detail"}
              defaultMessage={"Détails réparateur"}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <ProfessionChips
            title={intl.formatMessage({ id:"dossierAdmin.contact.skills", defaultMessage: "Compétences" })}
            professions={professions}
            reference={dossier.professions}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default DossierContactBlock
