import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Grid, TextField, SxProps, Theme } from '@mui/material'

export type PasswordInfos = {
  password: string
  password2: string
}

type TArtisanPasswordFormProps = {
  value: PasswordInfos
  onChange: (data: Partial<PasswordInfos>) => void
  errors?: Partial<Record<keyof PasswordInfos, boolean>>
  sx: SxProps<Theme>
}

const ArtisanPasswordForm = ({
  value,
  onChange,
  errors,
  sx,
}: TArtisanPasswordFormProps) => {
  const { password, password2 } = value
  const intl = useIntl()

  const inputChange = useCallback(
    (field: keyof PasswordInfos) => (ev: React.ChangeEvent<HTMLInputElement>) =>
      onChange({ [field]: ev.target.value }),
    [onChange],
  )

  const passwordDiff = useMemo(
    () => password !== '' && password2 !== '' && password !== password2,
    [password, password2],
  )

  return (
    <Grid container spacing={2} sx={sx}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="password"
          type="password"
          required
          fullWidth
          id="password"
          label={intl.formatMessage({ id: 'passwordLost.form.password', defaultMessage: 'Mot de passe' })}
          value={password}
          onChange={inputChange('password')}
          error={!!errors?.password}
          helperText={
            !!errors?.password &&
            (passwordDiff
              ? intl.formatMessage({ id: 'passwordLost.form.password.error.1', defaultMessage: 'Les mots de passe ne correspondent pas' })
              : intl.formatMessage({ id: 'passwordLost.form.password.error.2', defaultMessage: 'Champ requis' }))
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="password2"
          type="password"
          required
          fullWidth
          id="password2"
          label={intl.formatMessage({ id: 'passwordLost.form.confirmation', defaultMessage: 'Confirmation de mot de passe' })}
          value={password2}
          onChange={inputChange('password2')}
          error={!!errors?.password2}
          helperText={
            !!errors?.password2 &&
            (passwordDiff
              ? intl.formatMessage({ id: 'passwordLost.form.password.error.1', defaultMessage: 'Les mots de passe ne correspondent pas' })
              : intl.formatMessage({ id: 'passwordLost.form.password.error.2', defaultMessage: 'Champ requis' }))
          }
        />
      </Grid>
    </Grid>
  )
}
export default ArtisanPasswordForm
