import { IntlShape } from 'react-intl'

import { Artisan } from './Artisan'
import { Dossier } from './Dossier'

export type Contact = {
  id: string
  artisanId: string
  dossierId: string
  createdAt: string
  updatedAt: string
  status: ContactStatus
  artisan: Artisan
  dossier: Dossier
}

export type ContactStatus = 'Initie' | 'Appel' | 'Valide' | 'Annule'
export const contactStatuslibelles = (intl: IntlShape): Record<ContactStatus, string> => {
  return {
    Initie: intl.formatMessage({ id: "dossierAdmin.status.initiated", defaultMessage: "Initié par l'entreprise" }),
    Appel: intl.formatMessage({ id: "dossierAdmin.status.contacted", defaultMessage: 'Entreprise contactée' }),
    Valide: intl.formatMessage({ id: "dossierAdmin.status.devisValidated", defaultMessage: 'Devis validé' }),
    Annule: intl.formatMessage({ id: "dossierAdmin.status.aborted", defaultMessage: 'Contact annulé' }),
  }
}
