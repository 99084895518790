import React, { useState, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Box, Breadcrumbs, Grid, Button, Alert, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import type { FormState, Profession } from 'model'
import { ExtendedArtisan } from 'model/Artisan'
import ArtisanInfosForm from 'forms/ArtisanInfosForm'
import type { ArtisanInfos } from 'forms/ArtisanInfosForm'
import { updateCurrentArtisan } from 'api/ArtisanApi'
import type { UpdateArtisanPayload } from 'api/ArtisanApi'
import ProfessionInput from 'forms/ProfessionInput'
import { useSession } from 'stores/SessionProvider'
import { boxSx } from 'ui/ComponentsSx'
import CertificatForm from './certificats/CertificatForm'

type TProfilArtisanPageProps = {
  artisan: ExtendedArtisan
}

const ProfilArtisanPage = ({ artisan }: TProfilArtisanPageProps) => {
  const [updates, setUpdates] = useState<UpdateArtisanPayload>({})
  const [formState, setFormState] = useState<FormState>('ready')
  const { setArtisan } = useSession()

  const updatedArtisan = useMemo(() => ({ ...artisan, ...updates }), [artisan, updates])

  const artisanInfos = useMemo(
    () => ({
      nom: updatedArtisan.nom,
      siret: updatedArtisan.siret,
      email: updatedArtisan.email,
      telephone: updatedArtisan.telephone,
    }),
    [updatedArtisan],
  )

  const infosChange = useCallback(
    (updated: Partial<ArtisanInfos>) =>
      setUpdates(prevUpdates => ({ ...prevUpdates, ...updated })),
    [setUpdates],
  )
  const professionsChange = useCallback(
    (professions: Profession[]) =>
      setUpdates(prevUpdates => ({ ...prevUpdates, professions })),
    [setUpdates],
  )

  const onSubmit = useCallback(() => {
    setFormState('loading')
    updateCurrentArtisan(updates)
      .then(updatedArtisan => {
        setFormState('success')
        setArtisan(() => updatedArtisan)
        setUpdates({})
      })
      .catch(err => setFormState('error'))
  }, [updates, setArtisan, setUpdates])

  return (
    <>
      {artisan.professions.length > 0 && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: 3 }}
        >
          {[
            <Link key="1" component={RouterLink} to="/re62">
              <FormattedMessage
                id={"breadcrumb.dashboard"}
                defaultMessage={"Dashboard"}
              />
            </Link>,
            <Typography key="2" color="text.primary">
              <FormattedMessage
                id={"breadcrumb.artisan"}
                defaultMessage={"Profil réparateur"}
              />
            </Typography>,
          ]}
        </Breadcrumbs>
      )}

      <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {artisan.professions.length === 0 && (
          <Alert severity="info">
            <FormattedMessage
              id={"artisan.alert.error.first"}
              defaultMessage={"Veuillez mettre à jour les compétences de votre entreprise pour accéder à la liste des dossiers"}
            />
          </Alert>
        )}
        {!artisan.hasUploadedCertificatAssurance && (
          <Alert severity="error">
            <FormattedMessage
              id={"artisan.alert.error.second.1"}
              defaultMessage={"Veuillez importer votre certificat d’assurance de l’année en cours afin de compléter votre profil."}
            />
            <br />
            <FormattedMessage
              id={"artisan.alert.error.second.2"}
              defaultMessage={"Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail."}
            />
          </Alert>
        )}
        {!artisan.hasValidCertificatAssurance && artisan.hasUploadedCertificatAssurance && (
          <Alert severity="info">
            <FormattedMessage
              id={"artisan.alert.error.third"}
              defaultMessage={"Vous pourrez commencer à vous positionner sur les dossiers disponibles dès qu'un administrateur aura validé votre certificat d'assurance. Une notification vous sera envoyée par e-mail."}
            />
          </Alert>
        )}
      </Box>

      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"artisan.infos"}
          defaultMessage={"Mes coordonnées"}
        />
      </Typography>
      <Box sx={boxSx}>
        <ArtisanInfosForm value={artisanInfos} onChange={infosChange} />
      </Box>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"artisan.skills"}
          defaultMessage={"Mes compétences"}
        />
      </Typography>
      <Box sx={boxSx}>
        <ProfessionInput
          value={updatedArtisan.professions}
          onChange={professionsChange}
        />
      </Box>
      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"artisan.certificates"}
          defaultMessage={"Mes certificats"}
        />
      </Typography>
      <CertificatForm artisanId={artisan.id} isAdmin={false} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          {formState === 'success' && <Alert severity="success">
              <FormattedMessage
                id={"artisan.profileUpdated"}
                defaultMessage={"Profil mis à jour"}
              />
          </Alert>}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={onSubmit}
            color={formState === 'error' ? 'error' : 'primary'}
            disabled={formState === 'loading'}
          >
            <FormattedMessage
              id={"artisan.updateProfile"}
              defaultMessage={"Mettre à jour mon profil"}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
export default ProfilArtisanPage
