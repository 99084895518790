import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'

import { Artisan, Dossier } from 'model'
import { RecapItem } from './RecapItem'

type TDossierRecapContentProps = {
  dossier: Dossier
  artisan?: Artisan
}

const printId = (id: string): string => {
  // To have a nicer display, not necessarily unique
  const base = id.split('-')[0]
  return `${base.substring(0, 4)}-${base.substring(4, 8)}`
}

export const DossierRecapContent = ({ dossier, artisan }: TDossierRecapContentProps) => {
  const { id, description, logementHabitable, nombrePieces, budget, scoreAmplitude } =
    dossier
  const intl = useIntl()

  const ampleurText =
    scoreAmplitude === 1 ? 'Faible' : scoreAmplitude === 9 ? 'Elevée' : 'Moyenne'

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '14px' }}>
        <RecapItem
          title={intl.formatMessage({ id: 'dossierRecap.content.dossier', defaultMessage: 'Dossier n°' })}
          value={printId(id)}
          inline
        />
        <RecapItem
          title={intl.formatMessage({ id: 'dossierRecap.content.habitation.title', defaultMessage: 'Relogement' })}
          value={logementHabitable
            ? intl.formatMessage({ id: 'dossierRecap.content.habitation.non', defaultMessage: 'Non' })
            : intl.formatMessage({ id: 'dossierRecap.content.habitation.oui', defaultMessage: 'Oui' })}
          inline
        />
        <RecapItem
          title={intl.formatMessage({ id: 'dossierRecap.content.damagedRooms', defaultMessage: 'Pièces endommagées' })}
          value={'' + nombrePieces}
          inline
        />
        <RecapItem
          title={intl.formatMessage({ id: 'dossierRecap.content.amplitude', defaultMessage: 'Ampleur' })}
          value={ampleurText}
          inline
        />
      </Box>
      {budget && (
        <RecapItem
          title={intl.formatMessage({ id: 'dossierRecap.content.amount.title', defaultMessage: 'Montant de l\'expertise' })}
          value={`${budget} ${intl.formatMessage({ id: 'dossierRecap.content.amount.suffix', defaultMessage: '€ HT' })}`}
          inline
        />
      )}
      <RecapItem
        title={intl.formatMessage({ id: 'dossierRecap.content.description', defaultMessage: 'Description des dommages' })}
        value={description}
      />
    </>
  )
}
