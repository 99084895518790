import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Link as RouterLink } from 'react-router-dom'
import { Typography, Grid, Breadcrumbs, Link } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const MentionsLegales = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ marginBottom: 6 }}
      >
        {[
          <Link key="1" component={RouterLink} to="/re62">
            <FormattedMessage
              id={"breadcrumb.home"}
              defaultMessage={"Accueil"}
            />
          </Link>,
          <Typography key="2" color="text.primary">
            <FormattedMessage
              id={"breadcrumb.legal"}
              defaultMessage={"Mentions Légales"}
            />
          </Typography>,
        ]}
      </Breadcrumbs>

      <Typography variant="h2" component="h2" gutterBottom>
        <FormattedMessage
          id={"legal.title"}
          defaultMessage={"Éditeur du site :"}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id={"legal.section.1"}
          defaultMessage={"SARETEC DEVELOPPEMENT, société anonyme à conseil d’administration"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.2"}
          defaultMessage={"Au capital social de 1 356 080,00 euros"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.3"}
          defaultMessage={"Situé au 9-11 Rue Georges Enesco 94000 Créteil"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.4"}
          defaultMessage={"Inscrite au Registre du commerce et des sociétés de Créteil sous le numéro 352 603 575"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.5"}
          defaultMessage={"Numéro de téléphone : 01 49 56 84 00"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.6"}
          defaultMessage={"Adresse de courrier électronique : contact@reconstruireensemble62.fr"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.7"}
          defaultMessage={"Directeur de la publication :"}
        />
        <br />
        <FormattedMessage
          id={"legal.section.8"}
          defaultMessage={"Monsieur Jean-Vincent RAYMONDIS, Président du Conseil d’administration"}
        />
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h2" component="h2" gutterBottom>
        Création de site internet :
      </Typography>
      <Typography variant="body1" gutterBottom>
        Keywiiz by Saretec
      </Typography>
    </Grid>
  </Grid>
)
export default MentionsLegales
